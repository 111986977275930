import React, { Component } from 'react'

export class Help extends Component {
    constructor(props) {
        super()
        this.state = {
            active: false
        }
        this.toggleHelp = this.toggleHelp.bind(this)
    }
    toggleHelp(e){
        this.setState({active: !this.state.active})
    }
    render() {
        return (
            <div id="helpWidget" className={`is-up is-right dropdown${this.state.active ? " is-active" : ""}`}>
              <div className="dropdown-trigger" onClick={this.toggleHelp}>
                  {this.state.active ? "X" : "?"}
              </div>
              <div className="dropdown-menu" id="dropdown-menu" role="menu">
                <div className="dropdown-content">
                  <a onClick={this.toggleHelp} rel="noopener noreferrer" target="_blank" href="/feedback" className="dropdown-item">
                    Leave feedback
                  </a>
                  <a onClick={this.toggleHelp} rel="noopener noreferrer" target="_blank" href="/feedback" className="dropdown-item">
                    Roadmap
                  </a>
                  <hr className="dropdown-divider" />
                  <a onClick={this.toggleHelp} rel="noopener noreferrer" target="_blank" href="/contact" className="dropdown-item">
                    Contact us
                  </a>
                </div>
              </div>
            </div>
        )
    }
}

export default Help
