import React, { Component } from 'react'
import BoardHeader from './BoardHeader'
import { connect } from 'react-redux'
import { getPosts, getMorePosts, createPost, updateBoardFormState, getPostSearch, emptySearch, getStatusBoard, getBoard } from '../../actions/index'
import { createLoadingSelector, createErrorMessageSelector } from '../../selectors/index'
import { withRouter } from 'react-router'
import BoardNav from './BoardNav'
import BoardCard from './BoardCard'
import SearchBoardCard from './SearchBoardCard'
import Modal from './../Modal.jsx'

const loadingSelector = createLoadingSelector([
    'GET_POSTS',
    'GET_MORE_POSTS',
    'GET_SEARCH_POSTS',
])

const errorSelector = createErrorMessageSelector([
    'GET_POSTS',
    'GET_MORE_POSTS',
    'CREATE_POST',
])


const mapStateToProps = (state, ownProps) => {
    return {
        posts: state.rootReducer.posts,
        board: state.rootReducer.board,
        user: state.rootReducer.user,
        statuses: state.rootReducer.statuses,
        searched_posts: state.rootReducer.searched_posts,
        board_form_active: state.rootReducer.board_form_active,
        isFetching: loadingSelector(state),
        error: errorSelector(state),
    }
}

class Board extends Component {
    constructor() {
        super()
        this.showForm = this.showForm.bind(this)
    }
    // handleScroll(e){
    //     const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
    //     const body = document.body;
    //     const html = document.documentElement;
    //     const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight,  html.scrollHeight, html.offsetHeight);
    //     const windowBottom = windowHeight + window.pageYOffset;
    //     if (windowBottom >= (docHeight - 250)) {
    //       this.setState({
    //         bottom_reached: true
    //       });
    //     } else {
    //       this.setState({
    //         bottom_reached: false
    //       });
    //     }
    // }
    showForm(event) {
        event.preventDefault()
        if (this.props.user && !this.props.user.uuid) {
            this.props.history.push({pathname: "/signup/", state: { modal: true }})
        } else {
            // const newState = {title: "", description: "", formError: null}
            // if (this.props.type && this.props.board.types) {
            //     const type_uuid = this.props.board.types[this.props.type].uuid
            //     newState['selectedType'] = type_uuid;
            // }else{
            //     newState['selectedType'] = 0;
            // }

            this.props.updateBoardFormState(true);
            // this.setState(newState);
        }
    }
    render() {
        let statuses_count = Object.keys(this.props.board.active_statuses).length
        if(this.props.board.complete_status){
            statuses_count = statuses_count + 1
        }
        return (
                <>
                    {(this.props.board && this.props.user) &&
                        <div>
                            <BoardNav {...this.props} />
                            <BoardHeader {...this.props} />
                            <div className="board-wrapper">
                                {(this.props.searched_posts) ?
                                    <div className="container">
                                        <div className="columns">
                                            <div className="board column search-board" style={{marginTop: 0}}>
                                                <div className="board-search-label">
                                                    <h4>Related posts</h4>
                                                </div>
                                                <SearchBoardCard {...this.props} />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div style={{width: "95%", margin: "auto", maxWidth: (statuses_count < 4) ? "960px" : "1280px"}}>
                                        <div className="columns is-desktop">
                                            {Object.keys(this.props.board.active_statuses).map(key => (
                                                <div className="board column" key={this.props.board.active_statuses[key].uuid}>
                                                    <div className="board-card-status-label">
                                                        <span className="tag" style={{fontWeight: "600", color: "333", background: this.props.board.active_statuses[key].bg_color}}>{this.props.board.active_statuses[key].title}</span>
                                                    </div>
                                                    <BoardCard {...this.props} status={this.props.board.active_statuses[key]} />
                                                </div>
                                            ))}
                                            {this.props.board.complete_status &&
                                                <div className="board column">
                                                    <div className="board-card-status-label">
                                                        <span className="tag" style={{fontWeight: "600", color: "333", background: this.props.board.complete_status.bg_color}}>{this.props.board.complete_status.title}</span>
                                                    </div>
                                                    <BoardCard {...this.props} status={this.props.board.complete_status} />
                                                </div>
                                            }
                                            {(!Object.keys(this.props.board.active_statuses).length && !this.props.board.complete_status && !this.props.board_form_active) &&
                                                <div className="column">
                                                    <div className="card" style={{maxWidth: "600px", margin: "1rem auto"}}>
                                                        <div className="card-content">
                                                            {this.props.user.is_admin ?
                                                                <>
                                                                    <p>This is where people can see and vote on feature requests and ideas. Add requests with public statuses an they will appear here</p>
                                                                    <div>
                                                                        <button className="button is-primary" style={{background: this.props.board.color}} onClick={this.showForm}>Add your first request</button>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <p>There are no posts here yet</p>
                                                                    <p>Check back later, or give feedback:</p>
                                                                    <div>
                                                                        <button className="button is-primary" style={{background: this.props.board.color}} onClick={this.showForm}>Give feedback</button>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="powered-by">
                                <a target="_blank" rel="noopener noreferrer" href={`https://dashvine.com/?utm_medium=website&utm_campaign=poweredby&utm_source=${this.props.board.product.slug}`}>
                                    <svg width="12px" height="12px" viewBox="0 0 35 35" version="1.1" style={{verticalAlign: "middle", marginRight: ".2rem", display: "inline-block"}}>
                                        <defs>
                                            <linearGradient x1="42.2342062%" y1="-25.6534943%" x2="57.9396527%" y2="134.532563%" id="linearGradient-1">
                                                <stop stopColor="#4EB8A0" offset="0%"></stop>
                                                <stop stopColor="#2D6659" offset="100%"></stop>
                                            </linearGradient>
                                        </defs>
                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <path d="M0.00358383423,17.8577428 C0.0011983979,17.7387827 0,17.6195313 0,17.5 C0,15.4953366 0.337070433,13.5694002 0.957626976,11.7757752 L12.1610169,18.2440554 L12.1610169,24.8768401 L0.00358383423,17.8577428 Z M0.179250554,20.0141396 L12.1610169,26.9318157 L12.1610169,34.170635 C5.87881473,32.1603357 1.14078908,26.6974003 0.179250554,20.0141396 Z M1.63217554,10.1102504 C2.40083026,8.46254921 3.41857214,6.95441179 4.63697786,5.63426151 L12.1610169,9.9782675 L12.1610169,16.1890799 L1.63217554,10.1102504 Z M5.88879177,4.4066191 C7.6871188,2.81069053 9.81653618,1.57959685 12.1610169,0.829365003 L12.1610169,7.94355183 L5.88879177,4.4066191 Z M13.940678,34.6377874 C15.0899354,34.8752404 16.2803981,35 17.5,35 C27.1649831,35 35,27.1649831 35,17.5 C35,7.83501688 27.1649831,0 17.5,0 C16.2803981,0 15.0899354,0.124759581 13.940678,0.362212622 L13.940678,34.6377874 Z" fill="url(#linearGradient-1)"></path>
                                        </g>
                                    </svg>
                                    <span style={{display: "inline-block", verticalAlign: "middle"}}>
                                        Powered by Dashvine
                                    </span>
                                </a>
                            </div>
                            {this.props.modal &&
                                <Modal />
                            }
                        </div>
                    }
              </>
        )
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        { getMorePosts, getPosts, createPost, updateBoardFormState, getPostSearch, emptySearch, getStatusBoard, getBoard }
    )(Board)
)
