import React, { Component } from 'react'


export class UserIcon extends Component {
    render() {
        return (
            <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon-user-circle">
                <circle cx="12" cy="12" r="10" fill="#ddd" />
                <path fill="#888" d="M3.66 17.52A5 5 0 0 1 8 15h8a5 5 0 0 1 4.34 2.52 10 10 0 0 1-16.68 0zM12 13a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"/>
            </svg>
        )
    }
}

export default UserIcon
