import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { toggleVote } from '../../../actions/index'
import { withRouter } from 'react-router'
import ChatIcon from './../../icons/ChatIcon'
import UpArrowIcon from './../../icons/UpArrowIcon'

const mapStateToProps = state => {
    return {
        board: state.rootReducer.board,
        posts: state.rootReducer.posts,
        user: state.rootReducer.user,
    }
}

export class PostThumb extends Component {
    render() {
        let url = `/p/${this.props.post.slug}/`
        if (this.props.match.params.product_slug) {
            url = `/product/${this.props.match.params.product_slug}/p/${
                this.props.post.slug
            }/`
        }
        return (
            <div className="board-feedback-item admin-board-feedback-item">
                <div className="feedback-item-inner">
                    <div className="is-mobile columns is-vcentered feedback-post-thumb-column">
                        <div className="column">
                            <Link to={{ pathname: url, state: { modal: true }}}><span className="feedback-post-overlay"></span></Link>
                            <strong style={{color: this.props.board.color}}>{this.props.post.title}</strong>
                            {this.props.post.description &&
                                <p style={{opacity: "0.6", margin: 0}}>{this.props.post.description}</p>

                            }
                            <div className="post-thumb-meta">
                                {(this.props.post.total_votes) ?
                                    <div style={{display: "inline-block", marginRight: "1rem"}}>
                                        <UpArrowIcon /> <small style={{color: "#999"}}>{this.props.post.total_votes}</small>
                                    </div> : ""
                                }
                                {(this.props.post.comments && this.props.post.comments.length > 0) &&
                                    <div style={{display: "inline-block", marginRight: "1rem"}}>
                                        <ChatIcon /> <small style={{color: "#999"}}>{this.props.post.comments.length}</small>
                                    </div>
                                }
                                {this.props.post.type_selector_title && (
                                    <div style={{display: "inline-block", marginRight: "1rem"}} className="board-feedback-status-wrapper">
                                        <small>{this.props.post.type_selector_title}</small>
                                    </div>
                                )}
                                {this.props.post.status && (
                                    <div style={{display: "inline-block", marginRight: "1rem"}} className="board-feedback-status-wrapper">
                                        <small style={{color: this.props.post.status.color}}>{this.props.post.status.title}</small>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        { toggleVote }
    )(PostThumb)
)
