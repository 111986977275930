import React, { Component } from 'react'


export class PasswordIcon extends Component {
    render() {
        return (
            <svg height={this.props.size || "20"} width={this.props.size || "20"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path fill={this.props.color || "#ccc"} d="M5 10h14a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2zm6 6.73V18a1 1 0 0 0 2 0v-1.27a2 2 0 1 0-2 0z"/>
                <path fill={this.props.color || "#ccc"} d="M12 19a1 1 0 0 0 1-1v-1.27A2 2 0 0 0 12 13v-3h3V7a3 3 0 0 0-6 0v3H7V7a5 5 0 1 1 10 0v3h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-7v-3z"/>
            </svg>
        )
    }
}

export default PasswordIcon
