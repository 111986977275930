import React, { Component } from 'react'


export class CheckIcon extends Component {
    render() {
        return (
            <svg height={this.props.size || "15"} width={this.props.size || "15"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{display: "block"}}>
                <circle cx="12" cy="12" r="10" fill="transparent"/>
                <path fill="white" d="M10 14.59l6.3-6.3a1 1 0 0 1 1.4 1.42l-7 7a1 1 0 0 1-1.4 0l-3-3a1 1 0 0 1 1.4-1.42l2.3 2.3z"/>
            </svg>
        )
    }
}

export default CheckIcon
