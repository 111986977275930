import React, { Component } from 'react'
import CommentForm from './CommentForm';
import AvatarAndName from './../../AvatarAndName'

const ReactMarkdown = require('react-markdown')


export class Comment extends Component {
    constructor() {
        super()
        this.state = {
            replyActive: false,
        }
        this.toggleReply = this.toggleReply.bind(this)
    }
    toggleReply(e){
        if(e){
            e.preventDefault()
        }
        this.setState({replyActive: !this.state.replyActive})
    }
    render() {
        return (
            <div className="comment" style={{ margin: '2rem 0' }}>
                <div className="comment-header">
                    <div className="avatar-and-name" style={{ marginBottom: '0.5rem' }}>
                        <AvatarAndName identities={this.props.identities} color={this.props.board.color} user={this.props.comment.user} />
                        {(this.props.comment.status && this.props.comment.status.uuid) &&
                            <>
                                {' '}updated the status to{' '}
                                <strong>
                                    {this.props.comment.status.title}
                                </strong>
                            </>
                        }
                    </div>
                </div>
                <div className="comment-body">
                    <ReactMarkdown
                        linkTarget="_blank"
                        source={this.props.comment.content}
                    />
                </div>
                <div className="comment-footer">
                    <small className="has-text-grey">{this.props.comment.human_created}</small> - <small className="comment-reply-link has-text-grey" onClick={this.toggleReply}>{this.state.replyActive ? "Cancel" : "Reply"}</small>
                    {this.state.replyActive &&
                        <CommentForm {...this.props} reply={true} toggleReply={this.toggleReply} />
                    }
                    {this.props.comment.replies.map(reply_uuid => (
                        <div key={reply_uuid} style={{marginLeft: "1rem"}}>
                            <Comment {...this.props}
                                comment={this.props.comments[reply_uuid]}
                            />
                    </div>
                    ))}
                </div>
            </div>
        )
    }
}

export default Comment
