import React, { Component } from 'react'


export class PhotoIcon extends Component {
    render() {
        return (
            <svg width={this.props.size || "20"} height={this.props.size || "20"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path fill={this.props.color || "#ccc"} d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2zm9 6a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/><path d="M15.3 12.3a1 1 0 0 1 1.4 0l2 2a1 1 0 0 1 .3.7v3a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-3a1 1 0 0 1 .3-.7l4-4a1 1 0 0 1 1.4 0l3.3 3.29 1.3-1.3z"/>
            </svg>
        )
    }
}

export default PhotoIcon
