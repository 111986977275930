import React, { Component } from 'react'
import BoardForm from './BoardForm'

export class BoardHeader extends Component {
    render() {
        return (
            <>
                <section className="hero has-text-centered board-hero">
                  {(!this.props.board_form_active) &&
                      <div className="hero-body">
                       <div className="container">
                         <h2 className="subtitle">
                           {this.props.board.description || "Share your feedback or vote on other people's ideas"}
                         </h2>
                       </div>
                     </div>
                   }
                </section>
                <BoardForm {...this.props} />
            </>
        )
    }
}

export default BoardHeader
