import React, { Component } from 'react'
import AdminBase from './../AdminBase'
import { connect } from 'react-redux'
import { getDashboardPosts, getMoreDashboardPosts, dismissOnboardingMessageOne} from '../../../actions/admin'
import { updateBoardFormState, emptySearch, getPostSearch, createPost } from '../../../actions/index'
import { createLoadingSelector, createErrorMessageSelector } from '../../../selectors/index'
import { withRouter } from 'react-router'
import PostThumb from './PostThumb'
import Modal from './../../Modal'
import BoardForm from './../../board/BoardForm'
import merge from 'lodash/merge'
import { Link } from 'react-router-dom'
import PrivateBoardIcon from './../../board/PrivateBoardIcon'
import Onboarding from './Onboarding'

const loadingSelector = createLoadingSelector([
    'GET_DASHBOARD_POSTS',
])

const errorSelector = createErrorMessageSelector([
    'GET_DASHBOARD_POSTS',
])

const mapQueryParams = (location) => {
    const qs = require('qs')
    return qs.parse(location.search.slice(1))
}

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const sorts = ['latest', 'trending', 'top']

const mapStateToProps = (state, ownProps) => {
    return {
        posts: state.rootReducer.posts,
        board: state.rootReducer.board,
        user: state.rootReducer.user,
        next: state.rootReducer.next,
        searched_posts: state.rootReducer.searched_posts,
        sort: mapQueryParams(ownProps.location).sort,
        status: mapQueryParams(ownProps.location).status,
        statuses: state.rootReducer.statuses,
        type: mapQueryParams(ownProps.location).type,
        isFetching: loadingSelector(state),
        error: errorSelector(state),
        board_form_active: state.rootReducer.board_form_active,
    }
}

class Dashboard extends Component {
    constructor() {
        super()
        this.state = {
            sortMenuOpened: null,
            filterMenuOpened: null,
        }
        this.handleScroll = this.handleScroll.bind(this)
        this.generateFilterUrl = this.generateFilterUrl.bind(this)
        this.toggleDropDown = this.toggleDropDown.bind(this)
    }
    componentDidMount(){
        if(!this.props.modal){
            this.props.getDashboardPosts({product_slug: this.props.board.product.slug, sort: this.props.sort, status: this.props.status, type: this.props.type})
        }
        window.addEventListener('scroll', this.handleScroll);
    }
    componentDidUpdate(prevProps){
        if(`${this.props.sort}-${this.props.status}-${this.props.type}` !== `${prevProps.sort}-${prevProps.status}-${prevProps.type}`){
            this.props.getDashboardPosts({product_slug: this.props.board.product.slug, sort: this.props.sort, status: this.props.status, type: this.props.type})
            // this.props.getDashboardPosts({product_slug: this.props.board.product.slug, sort: this.props.sort, status: this.props.status, type: this.props.type})
        }
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    handleScroll(e){
        e.preventDefault();
        const ele = e.target
        if (!this.props.isFetching && this.props.next && !this.props.searched_posts) {
            const height = ele.innerHeight || ele.documentElement.clientHeight || ele.body.clientHeight;
            const scrollTop = ele.pageYOffset || ele.documentElement.scrollTop || ele.body.scrollTop;
            const offsetHeight = ele.documentElement.offsetHeight || ele.body.offsetHeight;
            const bottom = offsetHeight - scrollTop < height + 300
            if (bottom) {
                this.props.getMoreDashboardPosts(this.props.next)
            }
        }
    }
    generateFilterUrl(newQ){
        // TODO make filter work without having to do full page refresh
        // TODO if keys come in with a null, remove them
        const qs = require('qs')
        const q = {sort: this.props.sort, status: this.props.status, type: this.props.type}
        const string_query = qs.stringify(merge({}, q, newQ), { addQueryPrefix: true });
        return string_query
    }
    toggleDropDown(e, menu){
        e.preventDefault()
        if(menu === 'sort'){
            this.setState({sortMenuOpened: !this.state.sortMenuOpened})
        }else if(menu === 'filter'){
            this.setState({filterMenuOpened: !this.state.filterMenuOpened})
        }
    }
    render() {
        let current_status = null
        if(this.props.status && this.props.board.statuses && this.props.board.statuses[this.props.status]){
            current_status = this.props.board.statuses[this.props.status]
        }
        let current_type = null
        if(this.props.type && this.props.board && this.props.board.types && this.props.board.types[this.props.type]){
            current_type = this.props.board.types[this.props.type]
        }
        return (
            <AdminBase tab="feedback">
                <AdminBase.Body>
                    <div className="container">
                        <div className="section">
                            <div className="columns">
                                {(this.props.board.total_posts > 2) &&
                                    <div className="column is-one-quarter is-hidden-mobile">
                                        <aside className="menu">
                                           <p className="menu-label">Sort</p>
                                           <ul className="menu-list">
                                              {sorts.map(sort => (
                                                  <li key={sort}>
                                                      <Link style={(sort === this.props.sort || (!this.props.sort && sort === 'latest')) ? {background: this.props.board.bg_color} : {}} to={this.generateFilterUrl({sort: sort})}>
                                                          {capitalize(sort)}
                                                      </Link>
                                                  </li>
                                              ))}
                                            </ul>
                                            <p className="menu-label">Status</p>
                                            <ul className="menu-list">
                                                <li><Link to={this.generateFilterUrl({status: null})} style={(!this.props.status) ? {background: this.props.board.bg_color} : {}}>
                                                    All
                                                </Link></li>
                                                {Object.keys(this.props.board.statuses).map(slug => (
                                                   <li key={slug}><Link to={this.generateFilterUrl({status: slug})} style={(slug === this.props.status) ? {background: this.props.board.bg_color} : {}}>
                                                      {capitalize(this.props.board.statuses[slug].title)} {!this.props.board.statuses[slug].is_shown && <PrivateBoardIcon active={false} />} <span className="is-pulled-right status-counter-label">{this.props.board.statuses[slug].count}</span>
                                                   </Link></li>
                                                ))}
                                             </ul>
                                             {(this.props.board.types && Object.keys(this.props.board.types).length > 1) &&
                                             <>
                                                 <p className="menu-label">Type</p>
                                                 <ul className="menu-list">
                                                     <li><Link to={this.generateFilterUrl({type: null})} style={(!this.props.type) ? {background: this.props.board.bg_color} : {}}>
                                                         All
                                                     </Link></li>
                                                  {Object.keys(this.props.board.types).map(slug => (
                                                      <li key={slug}><Link to={this.generateFilterUrl({type: slug})} style={(slug === this.props.type) ? {background: this.props.board.bg_color} : {}}>
                                                          {capitalize(this.props.board.types[slug].title)}
                                                      </Link></li>
                                                  ))}
                                                </ul>
                                             </>}
                                        </aside>
                                    </div>
                                }
                                <div className="column">
                                    <div className="admin-dashboard-board-form">
                                        <BoardForm {...this.props} is_admin={true} />
                                    </div>
                                    <div className="admin-dashboard-feed">
                                        <Onboarding {...this.props} />
                                        {(this.props.searched_posts && this.props.board.total_posts > 2) ?
                                            <>
                                                <p><strong>Related posts</strong></p>
                                                {(this.props.searched_posts.length && !this.props.isFetching) ? (
                                                    <div>
                                                        {this.props.searched_posts.map(post_uuid => (
                                                            <PostThumb
                                                                key={post_uuid}
                                                                post={this.props.posts[post_uuid]}
                                                            />
                                                        ))}
                                                    </div>
                                                ) : <p>{!this.props.isFetching && "No related posts found"}</p>}
                                            </>
                                            :
                                            <>
                                                {(current_status || current_type ) &&
                                                    <div className="tags" style={{marginBottom: "0.5rem"}}>
                                                        {current_status &&
                                                            <div className="tag">
                                                                {current_status.title}
                                                                <Link to={this.generateFilterUrl({status: null})} className="delete is-small"></Link>
                                                            </div>
                                                        }
                                                        {current_type &&
                                                            <div className="tag">
                                                                {current_type.title}
                                                                <Link to={this.generateFilterUrl({type: null})} className="delete is-small"></Link>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {(this.props.posts && !this.props.isFetching) ?
                                                    <>
                                                    {Object.keys(this.props.posts).map(key => (
                                                        <PostThumb key={this.props.posts[key].uuid} post={this.props.posts[key]} />
                                                    ))}
                                                    </>
                                                : <>{(!this.props.isFetching && !this.props.posts && this.props.board.total_posts > 2) &&
                                                    <div className="card">
                                                        <div className="card-content">
                                                            No posts here yet, try adding some or <Link to={this.generateFilterUrl({type: null, status: null})}>clearing the filters</Link>
                                                        </div>
                                                    </div>
                                                }</>}
                                                {(this.props.isFetching) &&
                                                    <>
                                                        <div className="board-feedback-item admin-board-feedback-item" style={{height: "120px"}}>
                                                            <div className="feedback-item-inner">
                                                                <div className="is-mobile is-vcentered feedback-post-thumb-column">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="board-feedback-item admin-board-feedback-item" style={{height: "120px"}}>
                                                            <div className="feedback-item-inner">
                                                                <div className="is-mobile is-vcentered feedback-post-thumb-column">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="board-feedback-item admin-board-feedback-item" style={{height: "120px"}}>
                                                            <div className="feedback-item-inner">
                                                                <div className="is-mobile is-vcentered feedback-post-thumb-column">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="board-feedback-item admin-board-feedback-item" style={{height: "120px"}}>
                                                            <div className="feedback-item-inner">
                                                                <div className="is-mobile is-vcentered feedback-post-thumb-column">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="board-feedback-item admin-board-feedback-item" style={{height: "120px"}}>
                                                            <div className="feedback-item-inner">
                                                                <div className="is-mobile is-vcentered feedback-post-thumb-column">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.props.modal &&
                        <Modal />
                    }
                </AdminBase.Body>
            </AdminBase>
        )
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        { getDashboardPosts, updateBoardFormState, emptySearch,
          getPostSearch, createPost, getMoreDashboardPosts,
          dismissOnboardingMessageOne }
    )(Dashboard)
)
