export const createErrorMessageSelector = actions => (state) => {
 const errors = actions.map(action => state.errorReducer[action]);
 const clearErrors = errors.filter( i => i );
 if (clearErrors && clearErrors[0]) {
   return clearErrors[0];
 }
 return null;
};

export const createLoadingSelector = actions => state =>
    actions.some(action => state.loadingReducer[action])
