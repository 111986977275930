import React, { Component } from 'react'
import CheckIcon from './../../icons/CheckIcon'
import ExternalWindow from './../../icons/ExternalWindow'


export class Onboarding extends Component {
    constructor() {
        super()
        this.dismissOnboardingMessageOne = this.dismissOnboardingMessageOne.bind(this)
    }
    dismissOnboardingMessageOne(e){
        e.preventDefault()
        this.props.dismissOnboardingMessageOne(this.props.board.product.slug)
    }
    render() {
        return (
            <>
                {(this.props.board.total_posts < 3 && !this.props.isFetching && !this.props.board_form_active) &&
                    <div className="card" style={{background: "#3A9F89", position: "relative"}}>
                        <div className="speech-triangle"></div>
                        <div className="card-content has-text-white">
                            {(this.props.board.total_posts === 0) &&
                                <div>
                                    <p><strong className="has-text-white">Add 3 requests or ideas to get started</strong></p>
                                    <p>Your feedback board is all set up, but it's empty. Let's kick it off by adding 3 feature requests or ideas. Maybe an integration you're considering adding?</p>
                                </div>
                            }
                            {(this.props.board.total_posts === 1) &&
                                <div>
                                    <p>Nice work, only <strong className="has-text-white">2 more to go</strong>. Add another suggestion or request</p>
                                    <p><small>(hint: look at your product backlog or roadmap for ideas)</small></p>
                                </div>
                            }
                            {(this.props.board.total_posts === 2) &&
                                <div>
                                    <p>Great! Almost there</p>
                                    <p>Add just <strong className="has-text-white">one more request or idea</strong>, and your board will be ready to show teammates and customers</p>
                                </div>
                            }
                            {(this.props.board.total_posts > 0) &&
                                <div style={{borderRadius: "2px", border: "2px solid white", height: "1rem", overflow: "hidden", position: "relative"}}>
                                    <div style={{position: "absolute", top: 0, left: 0, bottom: 0, background: "white", right: 0, opacity: "0.8"}}></div>
                                    <div style={{position: "absolute", top: 0, left: 0, bottom: 0, background: "#3a9f89", width: `${parseInt(((this.props.board.total_posts / 3) * 100), 10)}%`}}></div>
                                </div>
                            }
                        </div>
                    </div>
                }
                {(this.props.board.total_posts > 2 && !this.props.isFetching && !this.props.user.onboarding_message_one_seen && this.props.user.is_owner) &&
                    <div className="card has-text-white" style={{background: "#3A9F89", position: "relative"}}>
                        <div className="speech-triangle"></div>
                        <div className="card-content">
                            <p>Great work - you're all set up</p>
                            <p><a rel="noopener noreferrer" target="_blank" href={this.props.board.board_redirect_url} style={{color: "white", textDecoration: "underline"}}>Check our your new feedback board in all its glory <span style={{marginLeft: "0.3rem", verticalAlign: "middle"}}><ExternalWindow color={"white"} /></span></a></p>
                            <p>Share it with teammates and customers so they can leave feedback and follow your progress</p>
                            <button className="button is-primary is-inverted is-outlined" style={{background: "#3a9f89", color: "white"}} onClick={this.dismissOnboardingMessageOne}>Got it <CheckIcon size={25} /></button>
                        </div>
                    </div>
                }
           </>
        )
    }
}

export default Onboarding
