import React, { Component } from 'react'

function Header() {
    return null
}

function Body() {
    return null
}

function Footer() {
    return null
}

function Modals() {
    return null
}

class Base extends Component {
    static Header = Header
    static Body = Body
    static Footer = Footer
    static Modals = Modals

    render() {
        const { children } = this.props
        const header = React.Children.toArray(children).find(
            child => child.type === Header
        )
        const body = React.Children.toArray(children).find(
            child => child.type === Body
        )
        const footer = React.Children.toArray(children).find(
            child => child.type === Footer
        )

        return (
            <div>
                <header>{header ? header.props.children : null}</header>
                <main>{body ? body.props.children : null}</main>
                <footer>{footer ? footer.props.children : null}</footer>
            </div>
        )
    }
}

export default Base
