import {schema} from 'normalizr';

// Define a users schema
export const identity = new schema.Entity('identities', {}, {'idAttribute': 'uuid'});

export const comment = new schema.Entity('comments', {
  user: identity,
}, {'idAttribute': 'uuid'});

export const comments = new schema.Array(comment)

comment.define({ replies: comments });

// export const status = new schema.Entity('statuses', {
// }, {'idAttribute': 'uuid'});

export const post = new schema.Entity('posts', {
    user: identity,
    comments: [ comment ]
}, {'idAttribute': 'slug'})


export const status = new schema.Entity('statuses', {
    posts: {
        results: [ post ]
    }
}, {'idAttribute': 'uuid'})
