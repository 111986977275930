import React, { Component } from 'react'
import Base from './../Base'
import { connect } from 'react-redux'
import { updateBoardTypes } from '../../actions/admin'
import {getBoard} from '../../actions/index'
import BoardSetTypesInner from './BoardSetTypesInner'

const mapStateToProps = (state, ownProps) => {
    return {
        board: state.rootReducer.board,
        user: state.rootReducer.user,
    }
}

class BoardSetTypes extends Component {
    constructor(props) {
        super()
        this.postSubmit = this.postSubmit.bind(this)
    }
    componentDidMount() {
        this.props.getBoard(this.props.match.params.product_slug)
    }
    postSubmit(){
        window.location = `/product/${this.props.board.product.slug}/style/`
    }
    render() {
        return (
            <Base>
                <Base.Header>
                    <div style={{padding: ".5rem", borderBottom: "1px solid #eee"}} className="has-text-centered section">
                        <ul className="list-inline list-inline-middle">
                            <li style={{margin: 0}}>
                                <svg width="35px" height="35px" viewBox="0 0 35 35" version="1.1" style={{display: "block"}}>
                                    <defs>
                                        <linearGradient x1="42.2342062%" y1="-25.6534943%" x2="57.9396527%" y2="134.532563%" id="linearGradient-1">
                                            <stop stopColor="#4EB8A0" offset="0%"></stop>
                                            <stop stopColor="#2D6659" offset="100%"></stop>
                                        </linearGradient>
                                    </defs>
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <path d="M0.00358383423,17.8577428 C0.0011983979,17.7387827 0,17.6195313 0,17.5 C0,15.4953366 0.337070433,13.5694002 0.957626976,11.7757752 L12.1610169,18.2440554 L12.1610169,24.8768401 L0.00358383423,17.8577428 Z M0.179250554,20.0141396 L12.1610169,26.9318157 L12.1610169,34.170635 C5.87881473,32.1603357 1.14078908,26.6974003 0.179250554,20.0141396 Z M1.63217554,10.1102504 C2.40083026,8.46254921 3.41857214,6.95441179 4.63697786,5.63426151 L12.1610169,9.9782675 L12.1610169,16.1890799 L1.63217554,10.1102504 Z M5.88879177,4.4066191 C7.6871188,2.81069053 9.81653618,1.57959685 12.1610169,0.829365003 L12.1610169,7.94355183 L5.88879177,4.4066191 Z M13.940678,34.6377874 C15.0899354,34.8752404 16.2803981,35 17.5,35 C27.1649831,35 35,27.1649831 35,17.5 C35,7.83501688 27.1649831,0 17.5,0 C16.2803981,0 15.0899354,0.124759581 13.940678,0.362212622 L13.940678,34.6377874 Z" fill="url(#linearGradient-1)"></path>
                                    </g>
                                </svg>
                            </li>
                            <li><div style={{fontWeight: "600", color: "#333"}}>Dashvine</div></li>
                        </ul>
                    </div>
                </Base.Header>
                <Base.Body>
                    <div className="container">
                        <div className="section">
                            <div style={{maxWidth: "600px", margin: "auto"}}>
                                <h3>What kind of feedback are you interested in collecting?</h3>
                                <p>Don't worry, you can update these later</p>
                                <BoardSetTypesInner {...this.props} postSubmit={this.postSubmit} onbaording={true} />
                            </div>
                        </div>
                    </div>
                </Base.Body>
                <Base.Footer>
                    <div style={{height: "0.5rem", background: "#3A9F89", width: "50%", opacity: "0.6", position: "fixed", bottom: 0}}></div>
                </Base.Footer>
            </Base>
        )
    }
}

export default connect(
        mapStateToProps,
        { updateBoardTypes, getBoard }
    )(BoardSetTypes)
