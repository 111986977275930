import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ExternalWindow from './../icons/ExternalWindow'
import Help from './Help'

function Header() {
    return null
}

function Body() {
    return null
}

function Footer() {
    return null
}

function Modals() {
    return null
}

const mapStateToProps = (state, ownProps) => {
    return { board: state.rootReducer.board,
             user: state.rootReducer.user }
}

class AdminBase extends Component {
    static Header = Header
    static Body = Body
    static Footer = Footer
    static Modals = Modals

    constructor() {
        super()
        this.state = {
            boardsDroopedDown: false,
            navOpen: false,
        }
        this.toggleBoardDropdown = this.toggleBoardDropdown.bind(this)
        this.toggleNav = this.toggleNav.bind(this)
    }
    toggleBoardDropdown(e){
        e.preventDefault()
        this.setState({"boardsDroopedDown": !this.state.boardsDroopedDown})
    }
    toggleNav(e){
        e.preventDefault();
        this.setState({navOpen: !this.state.navOpen})
    }
    render() {
        const { children } = this.props
        const header = React.Children.toArray(children).find(
            child => child.type === Header
        )
        const body = React.Children.toArray(children).find(
            child => child.type === Body
        )
        const footer = React.Children.toArray(children).find(
            child => child.type === Footer
        )
        const modals = React.Children.toArray(children).find(
            child => child.type === Modals
        )
        return (
            <>
                {this.props.board && this.props.user && (
                    <div>
                        <header>
                            <nav
                                className="navbar is-primary"
                                role="navigation"
                                aria-label="main navigation"
                                style={{zIndex: 99}}
                            >
                                <div className="navbar-brand">
                                    <a
                                        className="navbar-item"
                                        href="/"
                                    >
                                    <svg width="35px" height="35px" viewBox="0 0 35 35" version="1.1" style={{display: "block"}}>
                                        <g stroke="none" strokeWidth="1">
                                            <path d="M0.00358383423,17.8577428 C0.0011983979,17.7387827 0,17.6195313 0,17.5 C0,15.4953366 0.337070433,13.5694002 0.957626976,11.7757752 L12.1610169,18.2440554 L12.1610169,24.8768401 L0.00358383423,17.8577428 Z M0.179250554,20.0141396 L12.1610169,26.9318157 L12.1610169,34.170635 C5.87881473,32.1603357 1.14078908,26.6974003 0.179250554,20.0141396 Z M1.63217554,10.1102504 C2.40083026,8.46254921 3.41857214,6.95441179 4.63697786,5.63426151 L12.1610169,9.9782675 L12.1610169,16.1890799 L1.63217554,10.1102504 Z M5.88879177,4.4066191 C7.6871188,2.81069053 9.81653618,1.57959685 12.1610169,0.829365003 L12.1610169,7.94355183 L5.88879177,4.4066191 Z M13.940678,34.6377874 C15.0899354,34.8752404 16.2803981,35 17.5,35 C27.1649831,35 35,27.1649831 35,17.5 C35,7.83501688 27.1649831,0 17.5,0 C16.2803981,0 15.0899354,0.124759581 13.940678,0.362212622 L13.940678,34.6377874 Z" fill="white"></path>
                                        </g>
                                    </svg>
                                    Dashvine
                                    </a>
                                    <div role="button" onClick={this.toggleNav} className={`navbar-burger burger ${this.state.navOpen ? "is-active" : ""}`} aria-label="menu" aria-expanded={this.state.navOpen}>
                                      <span aria-hidden="true"></span>
                                      <span aria-hidden="true"></span>
                                      <span aria-hidden="true"></span>
                                    </div>
                                </div>
                                <div className={`navbar-menu ${this.state.navOpen ? "is-active" : ""}`}>
                                    <div className="navbar-start">
                                        <div className={`navbar-item has-dropdown ${(this.state.boardsDroopedDown) && "is-active"}`}>
                                            <span className="navbar-link" onClick={this.toggleBoardDropdown}>
                                                {this.props.board.title}
                                            </span>
                                            <div className="navbar-dropdown">
                                                <>
                                                    {Object.keys(this.props.user.products).map(key => (
                                                        <a key={this.props.user.products[key].uuid}
                                                            className="navbar-item"
                                                            href={`/product/${this.props.user.products[key].slug}/feedback/`}
                                                        >
                                                            {this.props.user.products[key].title}
                                                        </a>
                                                    ))}
                                                </>
                                                <hr className="navbar-divider" />
                                                <Link
                                                    className="navbar-item"
                                                    to="/product/create/"
                                                >
                                                    + Create new board
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="navbar-end">
                                        <Link
                                            className={`navbar-item${this.props.tab === 'feedback' ? " is-active" : ""}`}
                                            to={`/product/${this.props.board.product.slug}/feedback/`}
                                        >
                                            Feedback
                                        </Link>
                                        <Link
                                            className={`navbar-item${this.props.tab === 'users' ? " is-active" : ""}`}
                                            to={`/product/${this.props.board.product.slug}/users/`}
                                        >
                                            Users
                                        </Link>
                                        <Link
                                            className={`navbar-item${this.props.tab === 'settings' ? " is-active" : ""}`}
                                            to={`/product/${this.props.board.product.slug}/settings/`}
                                        >
                                            Settings
                                        </Link>
                                        <div className="navbar-item">
                                            <div className="buttons">
                                                <a
                                                    className="navbar-item button is-outlined is-inverted is-primary"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={this.props.board.board_redirect_url}
                                                    style={{background: "transparent", color: "white"}}
                                                >
                                                    View public board <span style={{marginLeft: "0.3rem", verticalAlign: "middle"}}><ExternalWindow color={"white"} /></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                            {header ? header.props.children : null}
                        </header>
                        <main>{body ? body.props.children : null}</main>
                        <div>
                            <main>{modals ? modals.props.children : null}</main>
                        </div>
                        <footer>{footer ? footer.props.children : null}</footer>
                        <Help />
                    </div>
                )}
            </>
        )
    }
}

export default connect(
    mapStateToProps,
    null
)(AdminBase)
