import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getPost, emptyPost, toggleVote, updateStatus, updatePost } from '../../actions/index'
import Comments from './comments/Comments'
import AvatarAndName from './../AvatarAndName'
import VoteButton from './VoteButton'
import PostEditing from './PostEditing'
import { withRouter } from 'react-router'


const ReactMarkdown = require('react-markdown')

const getPostFromList = (slug, posts) => {
    if(posts && posts[slug]){
        return posts[slug]
    }
}

const mapStateToProps = (state, ownProps) => {
    return { post: getPostFromList(ownProps.post_slug, state.rootReducer.posts),
             board: state.rootReducer.board,
             user: state.rootReducer.user,
             identities: state.rootReducer.identities,
             comments: state.rootReducer.comments}
}

export class Post extends Component {
    constructor() {
        super()
        this.state = {
            selectedStatus: "undefined",
            status_update_comment: "",
            is_editing: null,
        }
        this.updateSelectedStatus = this.updateSelectedStatus.bind(this)
        this.updateStatus = this.updateStatus.bind(this)
        this.updateStatusComment = this.updateStatusComment.bind(this)
        this.toggleEditing = this.toggleEditing.bind(this)
    }
    updateSelectedStatus(e){
        e.preventDefault();
        this.setState({selectedStatus: e.target.value})
    }
    updateStatusComment(e){
        e.preventDefault();
        this.setState({ status_update_comment: e.target.value })
    }
    updateStatus(e){
        e.preventDefault();
        if(this.state.selectedStatus !== this.props.post.status.uuid){
            const updated_status = {}
            updated_status['new_status'] = this.state.selectedStatus
            if(this.state.status_update_comment){
                updated_status['comment_body'] = this.state.status_update_comment
            }
            this.props.updateStatus(this.props.board.product.slug, this.props.post.uuid, updated_status).then(function(){
                this.setState({status_update_comment: ""})
            }.bind(this))
        }
    }
    componentDidMount() {
        if(this.props.board && this.props.board.product && this.props.board.product.slug) {
            this.props.getPost(this.props.board.product.slug, this.props.post_slug).then(function(){
                if(this.props.post.status){
                    this.setState({selectedStatus: this.props.post.status.uuid})
                }
            }.bind(this))
        }
    }
    toggleEditing(e){
        if(e){
            e.preventDefault()
        }
        this.setState({is_editing: !this.state.is_editing})
    }
    componentWillUnmount() {
        this.props.emptyPost()
    }
    render() {
        return (
            <div className="columns react-router-modal__modal__inner">
                {(this.props.post && this.props.post.uuid) && (
                    <div className="column">
                        <div className="post-detail modal-detail">
                            <div className="feedback-item-inner">
                                {(this.props.post.can_edit && this.state.is_editing) ?
                                        <PostEditing {...this.props} toggleEditing={this.toggleEditing} />
                                    :
                                    <>
                                        <div className="is-mobile columns is-vcentered" style={{ alignItems: 'center' }}>
                                            <div className="column">
                                                <div style={{marginBottom: "1rem"}}>
                                                    <VoteButton {...this.props} toggleEditing={this.toggleEditing} detail={true} />
                                                </div>
                                                <h4>
                                                    {this.props.post.title}
                                                </h4>
                                                {this.props.post.type_selector_title && (
                                                    <div className="board-feedback-status-wrapper">
                                                        <small>{this.props.post.type_selector_title}</small>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="is-mobile columns">
                                            <div className="column">
                                                <div className="avatar-and-name" style={{ marginBottom: '0.5rem' }}>
                                                    <AvatarAndName identities={this.props.identities} color={this.props.board.color} user={this.props.post.user} />
                                                </div>
                                                {this.props.post.description && (
                                                    <div className="post-body">
                                                        <ReactMarkdown
                                                            linkTarget="_blank"
                                                            source={this.props.post.description}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <Comments {...this.props} />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                )}
                <>
                    {(this.props.post && this.props.user.is_admin) && (
                        <div className="column admin-sidebar is-one-third">
                            {(this.props.post && this.props.post.identity) &&
                                <>
                                <div className="card">
                                    <div className="card-header">
                                        <div className="card-header-title">Update status</div>
                                    </div>

                                    <div className="card-content">
                                      <form onSubmit={this.updateStatus} className="form">
                                          <div className="field">
                                              <div className="control">
                                                  <div className="select is-fullwidth">
                                                      <select value={this.state.selectedStatus} onChange={this.updateSelectedStatus} required={true}>
                                                          {Object.keys(this.props.board.statuses).map(key => (
                                                              <option key={this.props.board.statuses[key].uuid} value={this.props.board.statuses[key].uuid}>{this.props.board.statuses[key].title}</option>
                                                          ))}
                                                      </select>
                                                  </div>
                                              </div>
                                           </div>
                                          {(this.state.selectedStatus !== this.props.post.status.uuid) &&
                                               <>
                                                   {this.state.selectedStatus !== "undefined" && <div className="field">
                                                       <label className="label">Add a note (optional)</label>
                                                       <textarea
                                                           className="textarea"
                                                           type="text"
                                                           rows="2"
                                                           placeholder="Add a note to the status update (optional)"
                                                           value={this.state.status_update_comment}
                                                           onChange={this.updateStatusComment}
                                                           style={{border: "1px solid #ccc"}}
                                                       />
                                                   </div>}
                                                   <div className="field">
                                                       <div className="control">
                                                           <button className="button is-fullwidth">Update status</button>
                                                        </div>
                                                   </div>
                                               </>
                                          }
                                      </form>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-content">
                                        <p style={{ marginBottom: '0.5rem' }}>
                                            <strong> {this.props.post.identity.name}</strong><small> - {this.props.post.identity.source}</small>
                                        </p>
                                        <ul className="list-unstyled">
                                            <li>
                                                {this.props.post.identity.source_email}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {(this.props.post.votes && this.props.post.identity) && (
                                    <div className="card">
                                      <header className="card-header">
                                          <strong className="card-header-title">
                                            Votes ({this.props.post.total_votes})
                                          </strong>
                                        </header>
                                        <div className="card-content">
                                            {this.props.post.vote_stats &&
                                                <div>
                                                    {Object.keys(this.props.post.vote_stats).map(
                                                        key => (
                                                            <div key={key} style={{marginBottom: "0.5rem"}}>
                                                                <label className="label">{this.props.post.vote_stats[key].title} ({this.props.post.vote_stats[key].percent})</label>
                                                                <div style={{height: "0.3rem", width: this.props.post.vote_stats[key].percent, background: this.props.board.color, opacity: this.props.post.vote_stats[key].opacity}}></div>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            }
                                            {Object.keys(this.props.post.votes).map(
                                                key => (
                                                    <p key={this.props.post.votes[key].uuid}>
                                                        <strong>{this.props.post.votes[key].identity.name}</strong> <small> - {this.props.post.votes[key].identity.source}</small><br />
                                                        <small>{this.props.post.votes[key].identity.source_email}</small><br />
                                                        <small>{this.props.post.votes[key].importance_string}</small>
                                                    </p>
                                                )
                                            )}
                                        </div>
                                    </div>
                                )}
                                </>
                            }
                        </div>
                    )}
                    </>
            </div>
        )
    }
}

export default withRouter(connect(
    mapStateToProps,
    { getPost, emptyPost, toggleVote, updateStatus, updatePost }
)(Post))
