import React, { Component } from 'react'
import PostThumb from './PostThumb'

class SearchBoardCard extends Component {
    render() {
        return (
            <div className="board-inner">
                <div className="board-body">
                    {(this.props.searched_posts.length && !this.props.isFetching) ? (
                        <div>
                            {this.props.searched_posts.map(post_uuid => (
                                <PostThumb
                                    key={post_uuid}
                                    post={this.props.posts[post_uuid]}
                                />
                            ))}
                        </div>
                    ) : <p>{!this.props.isFetching && "No related posts found"}</p>}
                </div>
            </div>
        )
    }
}

export default SearchBoardCard
