import React, { Component } from 'react'
import Base from './Base'

class NotFound extends Component {
    render() {
        return (
            <Base>
                <Base.Body>
                    <div>
                        <h1>404 - Not found</h1>
                        <p>
                            The page you're looking for is not here.{' '}
                            <a href="/">Click here to head back home.</a>
                        </p>
                    </div>
                </Base.Body>
            </Base>
        )
    }
}

export default NotFound
