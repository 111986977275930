import React, { Component } from 'react'
import { connect } from 'react-redux'
import AdminBase from './AdminBase'
import { Link } from 'react-router-dom'

const mapStateToProps = (state, ownProps) => {
    return { board: state.rootReducer.board,
             user: state.rootReducer.user }
}

export class BoardAdvancedSettings extends Component {
    render() {
        return (
            <AdminBase tab="settings">
                <AdminBase.Header>
                <div className="tabs settings-tabs">
                  <ul>
                    <li><Link to={{pathname: `/product/${this.props.board.product.slug}/settings/`}}>Board style</Link></li>
                    <li><Link to={{pathname: `/product/${this.props.board.product.slug}/settings/types/`}}>Types</Link></li>
                    <li className="is-active"><Link to={{pathname: `/product/${this.props.board.product.slug}/settings/advanced/`}}>Advanced</Link></li>
                    <li><Link to={{pathname: `/product/${this.props.board.product.slug}/settings/team/`}}>Team & people</Link></li>
                  </ul>
                </div>
                </AdminBase.Header>
                <AdminBase.Body>
                <div className="section">
                    <div className="container">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-header-title">Custom domain</div>
                            </div>
                            <div className="card-content">
                                <p>You can host your feedback board on a custom domain (e.g. <strong>feedback.yourdomain.com</strong> instead of <strong>{this.props.board.subdomain}.dashvine.com</strong>)</p>
                                <p>Please get in touch if that's something you want to do and we will get you set up: <strong>ramy@dashvine.com</strong></p>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <div className="card-header-title">Single sign on (SSO)</div>
                            </div>
                            <div className="card-content">
                                <p>By default, people have to create a Dashvine account to give feedback and vote on requests on your feedback board.</p>
                                <p>You can change that so they can use their existing account with your service to log into your feedback board - this means there's one less account for your customers to manage and means you have more information about the people using your feedback board.</p>
                                <p>SSO is great, but it does require some development time to set up (usually not long, about 30 minutes)</p>
                                <p>Please get in touch if that's something you want to do and we will get you set up: <strong>ramy@dashvine.com</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
                </AdminBase.Body>
            </AdminBase>
        )
    }
}

export default connect(
    mapStateToProps,
    {}
)(BoardAdvancedSettings)
