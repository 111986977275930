import React, { Component } from 'react'
import { connect } from 'react-redux'
import AdminBase from './AdminBase'
import BoardSetStyleInner from './BoardSetStyleInner'
import { Link } from 'react-router-dom'

const mapStateToProps = (state, ownProps) => {
    return { board: state.rootReducer.board,
             user: state.rootReducer.user }
}

export class BoardSettings extends Component {
    render() {
        return (
            <AdminBase tab="settings">
                <AdminBase.Header>
                <div className="tabs settings-tabs">
                    <ul>
                      <li className="is-active"><Link to={{pathname: `/product/${this.props.board.product.slug}/settings/`}}>Board style</Link></li>
                      <li><Link to={{pathname: `/product/${this.props.board.product.slug}/settings/types/`}}>Types</Link></li>
                      <li><Link to={{pathname: `/product/${this.props.board.product.slug}/settings/advanced/`}}>Advanced</Link></li>
                      <li><Link to={{pathname: `/product/${this.props.board.product.slug}/settings/team/`}}>Team & people</Link></li>
                    </ul>
                </div>
                </AdminBase.Header>
                <AdminBase.Body>
                    <BoardSetStyleInner />
                </AdminBase.Body>
            </AdminBase>
        )
    }
}

export default connect(
    mapStateToProps,
    {}
)(BoardSettings)
