export const ADD_POST = "ADD_POST";
export const FOUND_BAD_WORD = "FOUND_BAD_WORD";
export const GET_BOARD_SUCCESS = "GET_BOARD_SUCCESS";
export const GET_BOARD_FAILURE = "GET_BOARD_FAILURE";
export const GET_POST_SUCCESS = "GET_POST_SUCCESS";
export const GET_POST_FAILURE = "GET_POST_FAILURE";
export const GET_POSTS_SUCCESS = "GET_POSTS_SUCCESS";
export const GET_POSTS_FAILURE = "GET_POSTS_FAILURE";
export const UPDATE_VOTE_SUCCESS = "UPDATE_VOTE_SUCCESS";
export const UPDATE_VOTE_FAILURE = "UPDATE_VOTE_FAILURE";
export const LOGGED_IN = "LOGGED_IN";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";
export const GET_MORE_POSTS_SUCCESS = "GET_MORE_POSTS_SUCCESS";
export const GET_MORE_POSTS_FAILURE = "GET_MORE_POSTS_FAILURE";
export const GET_MORE_POSTS_REQUEST = "GET_MORE_POSTS_REQUEST";
export const GET_POSTS_REQUEST = "GET_POSTS_REQUEST";
export const CREATE_POST_REQUEST = "CREATE_POST_REQUEST";
export const CREATE_POST_SUCCESS = "CREATE_POST_SUCCESS";
export const CREATE_POST_FAILURE = "CREATE_POST_FAILURE";
export const CREATE_COMMENT_REQUEST = "CREATE_COMMENT_REQUEST";
export const CREATE_COMMENT_SUCCESS = "CREATE_COMMENT_SUCCESS";
export const CREATE_COMMENT_FAILURE = "CREATE_COMMENT_FAILURE";
export const UPDATE_BOARD_FORM_STATE = "UPDATE_BOARD_FORM_STATE";
export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";
export const UPDATE_STATUS_REQUEST = "UPDATE_STATUS_REQUEST";
export const UPDATE_STATUS_SUCCESS = "UPDATE_STATUS_SUCCESS";
export const GET_SEARCH_POSTS_SUCCESS = "GET_SEARCH_POSTS_SUCCESS";
export const GET_SEARCH_POSTS_REQUEST = "GET_SEARCH_POSTS_REQUEST";
export const GET_SEARCH_POSTS_FAILURE = "GET_SEARCH_POSTS_FAILURE";
export const EMPTY_SEARCH = "EMPTY_SEARCH";
export const GET_DASHBOARD_POSTS_REQUEST = "GET_DASHBOARD_POSTS_REQUEST";
export const GET_DASHBOARD_POSTS_SUCCESS = "GET_DASHBOARD_POSTS_SUCCESS";
export const EMPTY_BOARD = "EMPTY_BOARD";
export const TOGGLE_EDIT_POST = "TOGGLE_EDIT_POST";
export const UPDATE_POST_SUCCESS = "UPDATE_POST_SUCCESS"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const GET_MORE_DASHBOARD_POSTS_REQUEST = "GET_MORE_DASHBOARD_POSTS_REQUEST"
export const GET_MORE_DASHBOARD_POSTS_SUCCESS= "GET_MORE_DASHBOARD_POSTS_SUCCESS"
