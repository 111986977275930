import React, { Component } from 'react'
import AdminBase from './../AdminBase'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'


const mapStateToProps = (state, ownProps) => {
    return { board: state.rootReducer.board,
             user: state.rootReducer.user}
}

export class Users extends Component {
    render() {
        return (
            <AdminBase tab="users">
                <AdminBase.Body>
                    <div className="section">
                        <table className="table is-fullwidth card is-hoverable">
                            <thead>
                                <tr>
                                    <td>
                                        <strong>Name</strong>
                                    </td>
                                    <td>
                                        <strong>Email</strong>
                                    </td>
                                    <td>
                                        <strong>Source</strong>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(this.props.board.identities).map(key => (
                                    <tr key={this.props.board.identities[key].uuid}>
                                        <td>
                                            <Link to={`/product/${this.props.board.product.slug}/board/users/u/${this.props.board.identities[key].uuid}/`}>{this.props.board.identities[key].name}</Link>
                                        </td>
                                        <td>
                                            <Link to={`/product/${this.props.board.product.slug}/board/users/u/${this.props.board.identities[key].uuid}/`}>{this.props.board.identities[key].source_email}</Link>
                                        </td>
                                        <td>
                                            <Link to={`/product/${this.props.board.product.slug}/board/users/u/${this.props.board.identities[key].uuid}/`}>{this.props.board.identities[key].source}</Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </AdminBase.Body>
            </AdminBase>
        )
    }
}

export default connect(
    mapStateToProps,
    {}
)(Users)
