import React, { Component } from 'react'
import PrivateBoardIcon from './PrivateBoardIcon'


export class BoardForm extends Component {
    constructor() {
        super()
        this.state = {
            title: '',
            description: '',
            selectedType: 0,
            formError: null,
            post_created: null,
            fullFormShown: false,
            selectedImportance: 1,
            selectedStatus: 'being-considered',
        }
        this.checkQS = this.checkQS.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.showForm = this.showForm.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this)
        this.clearForm = this.clearForm.bind(this)
        this.updateSelectedType = this.updateSelectedType.bind(this);
        this.showFullForm = this.showFullForm.bind(this);
        this.selectImportance = this.selectImportance.bind(this);
        this.clearSuccessMessage = this.clearSuccessMessage.bind(this);
        this.selectStatus = this.selectStatus.bind(this);
    }
    componentDidMount() {
        // calling the new action creator
        this.checkQS()
    }
    componentWillUnmount() {
        if(this.props.board_form_active){
            this.clearForm()
        }
    }
    showFullForm(e){
        if(e){
            e.preventDefault();
        }
        this.setState({fullFormShown: true});
    }
    selectImportance(e, importance){
        e.preventDefault()
        this.setState({selectedImportance: importance});
    }
    selectStatus(e, status){
        e.preventDefault()
        if(status === this.state.selectedStatus){
            this.setState({selectedStatus: null});
        }else{
            this.setState({selectedStatus: status});
        }
    }
    clearSuccessMessage(e){
        e.preventDefault();
        this.setState({post_created: null})
    }
    checkQS() {
        const qs = require('qs')
        if (this.props.location.search) {
            const qu = qs.parse(this.props.location.search.slice(1))
            if (
                qu.title &&
                this.props.user &&
                this.props.user.uuid &&
                !this.props.title
            ) {
                const newFB = { title: qu.title.charAt(0).toUpperCase() + qu.title.substr(1)}
                this.props.getPostSearch(this.props.board.product.slug, qu.title);
                if (qu.description) {
                    newFB['description'] = qu.description
                }
                this.props.updateBoardFormState(true);
                this.setState(newFB)
            }
        }
    }
    handleChange(event){
        const newState = {formError: null};
        if(event.target.value){
            newState["title"] = event.target.value.charAt(0).toUpperCase() + event.target.value.substr(1)
        }else{
            newState["title"] = ''
        }
        this.setState(newState, () => {
            if(this.state.title){
                this.props.getPostSearch(this.props.board.product.slug, this.state.title).then(function(){
                    if(!this.state.title){
                        this.props.emptySearch()
                    }
                }.bind(this));
            }else{
                this.props.emptySearch()
            }
        })
    }
    handleDescriptionChange(event) {
        this.setState({ description: event.target.value, 'formError': null})
    }
    updateSelectedType(e){
        this.setState({selectedType: e.target.value, 'formError': null});
    }
    showForm(event) {
        event.preventDefault()
        if (this.props.user && !this.props.user.uuid) {
            this.props.history.push({pathname: "/signup/", state: { modal: true }})
        } else {
            const newState = {title: "", description: "", formError: null}
            if (this.props.type && this.props.board.types) {
                const type_uuid = this.props.board.types[this.props.type].uuid
                newState['selectedType'] = type_uuid;
            }else{
                newState['selectedType'] = 0;
            }

            this.props.updateBoardFormState(true);
            this.setState(newState);
        }
    }
    clearForm(event) {
        if(event){
            event.preventDefault()
        }
        this.props.emptySearch();
        this.props.updateBoardFormState(false);
        this.setState({ title: '', description: '', formError: null, fullFormShown: false });
    }
    handleSubmit(event) {
        event.preventDefault()
        const data = {
            title: this.state.title,
            description: this.state.description,
            importance: this.state.selectedImportance,
        }
        if(!this.state.title){
            this.setState({formError: "Please add a title"})
        }else if(Object.keys(this.props.board.types).length > 1 && !this.state.selectedType){
            this.setState({formError: "Please select a feedback type"})
        }else{
            if(this.state.selectedType){
                data['type'] = this.state.selectedType
            }
            if(this.state.selectedStatus){
                data['status'] = this.state.selectedStatus
            }
            const url = `/_/api/v1/product/${this.props.board.product.slug}/board/post/create/`
            this.props.createPost(url, data).then(function(response){
                if(!this.props.getDashboardPosts){
                    this.setState({'post_created': response.slug});
                    try{
                        if(this.props.user.is_admin && data['status'] && this.props.board.statuses && this.props.board.statuses[data['status']]){
                            const status_uuid = this.props.board.statuses[data['status']].uuid
                            if(this.props.board.statuses[data['status']].is_shown){
                                if(!this.props.statuses || (this.props.statuses && !this.props.statuses[status_uuid])){
                                    this.props.getBoard()
                                }else{
                                    this.props.getStatusBoard(this.props.board.product.slug, this.props.board.statuses[data['status']].uuid)
                                }
                            }
                        }
                    }catch(e){
                        console.log(e)
                    }
                }
            }.bind(this))

            this.clearForm(event)
        }
    }
    render() {
        const selectStyle = {};
        if(this.state.selectedType === 0){
            selectStyle['color'] = "#999";
        }
        let created_post = null
        if(this.state.post_created && this.props.posts[this.state.post_created]){
            created_post = this.props.posts[this.state.post_created]
        }
        return (
                <div className="board-form-outer">
                    <div className={`board-form-click-input-wrapper ${this.props.board_form_active ? "active" : ""}`} style={{border: `1px solid ${this.props.board.color}`}}>
                        <div className="content">
                            {!this.props.board_form_active ?
                                <div className="board-form-click-input has-text-centered" onClick={this.showForm}>
                                    + Share a new suggestion or request
                                </div> :
                                 <form className="create-feedback-form active has-text-left" onSubmit={this.handleSubmit}>
                                    <span
                                        className="modal-close is-large modal-close-darker"
                                        onClick={this.clearForm}
                                        aria-label="close">
                                    </span>
                                    <div className="field">
                                        <label className="label">What is your request or suggestion?</label>
                                        <input
                                            className="input"
                                            type="text"
                                            value={this.state.title}
                                            autoFocus={true}
                                            onChange={this.handleChange}
                                            required={true}
                                        />
                                    </div>
                                    {(this.state.fullFormShown) ?
                                        <>
                                            {(Object.keys(this.props.board.types).length) > 1 &&

                                                    <div className="field">
                                                       <label className="label">What type of feedback is this?</label>
                                                        <div className="control">
                                                            <div className="select is-fullwidth">
                                                              <select style={selectStyle} value={this.state.selectedType} onChange={this.updateSelectedType} required={true}>
                                                                  <option value={0} disabled={true}>- Select type -</option>
                                                                  {Object.keys(this.props.board.types).map(
                                                                      key => (
                                                                          <option value={this.props.board.types[key].uuid} key={this.props.board.types[key].uuid}>{this.props.board.types[key].selector_title}</option>
                                                                      )
                                                                  )}
                                                              </select>
                                                            </div>
                                                          </div>
                                                    </div>

                                            }
                                            <div className="field">
                                                <label className="label">More details</label>
                                                <textarea
                                                    className="textarea"
                                                    type="text"
                                                    rows="2"
                                                    placeholder="Tell us more about what you're after and why you're making the request or suggestion"
                                                    value={this.state.description}
                                                    onChange={this.handleDescriptionChange}
                                                />
                                            </div>
                                            <div className="field" style={{marginBottom: "1.5rem", borderBottom: "1px solid #eee", paddingBottom: "1rem"}}>
                                                <label className="label">How important is this to you?</label>
                                                <div className="field is-grouped select-importance-buttons">
                                                  <div className="control">
                                                    <button type="button" onClick={(e) => this.selectImportance(e, 1)} className={`button is-rounded is-small ${(this.state.selectedImportance === 1) && "active"}`} style={(this.state.selectedImportance === 1) ? {borderColor: this.props.board.color, background: this.props.board.color} : {}}>
                                                      Nice to have
                                                    </button>
                                                  </div>
                                                  <div className="control">
                                                      <button type="button" onClick={(e) => this.selectImportance(e, 2)} className={`button is-rounded is-small ${(this.state.selectedImportance === 2) && "active"}`} style={(this.state.selectedImportance === 2) ? {borderColor: this.props.board.color, background: this.props.board.color} : {}}>
                                                        Important
                                                      </button>
                                                  </div>
                                                  <div className="control">
                                                      <button type="button" onClick={(e) => this.selectImportance(e, 3)} className={`button is-rounded is-small ${(this.state.selectedImportance === 3) && "active"}`} style={(this.state.selectedImportance === 3) ? {borderColor: this.props.board.color, background: this.props.board.color} : {}}>
                                                        Critical
                                                      </button>
                                                  </div>
                                                </div>
                                            </div>
                                            {this.props.user.is_admin &&
                                                <div className="field" style={{marginBottom: "1.5rem", borderBottom: "1px solid #eee", paddingBottom: "1rem"}}>
                                                    <label className="label">Status</label>
                                                    <div className="field is-grouped select-importance-buttons">
                                                        {Object.keys(this.props.board.statuses).map(key => (
                                                            <React.Fragment key={key}>
                                                            {(this.props.board.total_posts > 2 || this.props.board.statuses[key].is_shown) &&
                                                                <div key={key} className="control">
                                                                  <button type="button" onClick={(e) => this.selectStatus(e, key)} className={`button is-rounded is-small ${(this.state.selectedStatus === key) && "active"}`} style={(this.state.selectedStatus === key) ? {borderColor: this.props.board.color, background: this.props.board.color} : {}}>
                                                                    {!this.props.board.statuses[key].is_shown && <PrivateBoardIcon active={(this.state.selectedStatus === key)} />}{this.props.board.statuses[key].title}
                                                                  </button>
                                                                </div>
                                                            }
                                                            </React.Fragment>
                                                        ))}
                                                    </div>
                                                </div>
                                            }
                                            <div className="field">
                                                    <button
                                                        type="submit"
                                                        className="button is-primary is-pulled-right"
                                                        disabled={!this.state.title}
                                                        style={{
                                                            borderColor: this.props.board.color,
                                                            background: this.props.board.color,
                                                            color: 'white',
                                                            display: "block",
                                                        }}
                                                    >
                                                        Submit <span className="is-hidden-mobile">feedback</span>
                                                    </button>

                                            </div>
                                        </> :
                                        <button
                                            className="button is-primary is-pulled-right"
                                            onClick={this.showFullForm}
                                            disabled={!this.state.title}
                                            style={{
                                                borderColor: this.props.board.color,
                                                background: this.props.board.color,
                                                color: 'white',
                                                display: "block",
                                            }}
                                        >
                                            Continue
                                        </button>
                                    }
                                    {this.state.formError && <p className="has-text-danger has-text-centered">{this.state.formError}</p>}
                                    {this.props.error && <p className="has-text-danger has-text-centered">{this.props.error}</p>}
                                </form>
                            }
                        </div>
                    </div>
                        {created_post &&
                            <div className="notification is-default" style={{border: "1px solid #ccc", margin: "1rem auto", maxWidth: "700px"}}>
                                <button className="delete" onClick={this.clearSuccessMessage}></button>
                                <strong>Thank you for your feedback.</strong> We'll keep you updated by email
                            </div>
                        }
                </div>
            )
      }
}

export default BoardForm
