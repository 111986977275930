import React, { Component } from 'react'


export class EmailIcon extends Component {
    render() {
        return (
            <svg height="20" width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon-mail">
                <path fill="#ccc" d="M22 8.62V18a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8.62l9.55 4.77a1 1 0 0 0 .9 0L22 8.62z"/>
                <path fill="#ccc" d="M12 11.38l-10-5V6c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v.38l-10 5z"/>
            </svg>
        )
    }
}

export default EmailIcon
