import React, { Component } from 'react'
import ReactTooltip from 'react-tooltip'
import PasswordIcon from './../icons/PasswordIcon'

export class PrivateBoardIcon extends Component {
    render() {
        return (
                <>
                    <span data-tip data-for='private-board-icon-tt' style={{marginRight: "0.1rem", opacity: "0.6"}}>
                        <PasswordIcon size="15" color={this.props.active ? "white" : "#666"} />
                    </span>
                    <ReactTooltip id="private-board-icon-tt" effect="solid" aria-haspopup='true'>
                        <div style={{maxWidth: "10rem"}}>
                            This status is not on your public board by default, but posts with this status can still appear in search results & related posts
                        </div>
                    </ReactTooltip>
                </>
        )
    }
}

export default PrivateBoardIcon
