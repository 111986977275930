import React, { Component } from 'react'
import { connect } from 'react-redux'
import { login } from '../actions/index'
import FacebookIcon from './icons/FacebookIcon'
import GoogleIcon from './icons/GoogleIcon'
import UserIcon from './icons/UserIcon'
import EmailIcon from './icons/EmailIcon'
import PasswordIcon from './icons/PasswordIcon'
import { createLoadingSelector, createErrorMessageSelector } from './../selectors/index'


const loadingSelector = createLoadingSelector([
    'SIGNUP',
])

const errorSelector = createErrorMessageSelector([
    'SIGNUP',
])

function mapDispatchToProps(dispatch) {
    return {
        login: user => dispatch(login(user)),
    }
}

const mapStateToProps = state => {
    return {
        board: state.rootReducer.board,
        isFetching: loadingSelector(state),
        error: errorSelector(state),
    }
}

class Login extends Component {
    constructor() {
        super()
        this.state = {
            email: '',
            password: '',
            name: '',
            signingUp: true,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.toggleLoginSignup = this.toggleLoginSignup.bind(this)
    }
    componentDidMount() {
        if(this.props.initialLogin){
            this.setState({'signingUp': false})
        }
    }
    handleChange(event) {
        this.setState({ [event.target.id]: event.target.value })
    }
    toggleLoginSignup(event){
        event.preventDefault();
        this.setState({signingUp: !this.state.signingUp})
    }
    handleSubmit(event) {
        event.preventDefault()
        const login_data = { ...this.state }
        const data = {
            login_data: login_data,
            next_url: this.props.next_url || '/',
        }
        this.props.login(data)
    }
    render() {
        const { email, password, name } = this.state
        return (
            <div className="react-router-modal__modal__inner">
                <div className="modal-detail">
                    <div style={{ marginBottom: '1rem' }}>
                        <p>Please log in to leave feedback & requests:</p>
                    </div>
                    {this.props.board && (
                        <div>
                            {this.props.board.sso_login_active &&
                             this.props.board.sso_login_url && (
                                 <div className="columns">
                                     <div className="column" style={{padding: "0.5rem"}}>
                                        <a style={{color: this.props.board.color}}
                                            className="button is-fullwidth is-auth is-SSO"
                                            href={this.props.board.sso_login_url}
                                        >
                                            {this.props.board.logo && (
                                                <img
                                                    src={this.props.board.logo}
                                                    alt={`${
                                                        this.props.board.title
                                                    } logo`}
                                                    height="30"
                                                    style={{
                                                        height: '30px',
                                                        marginRight: '0.5rem',
                                                    }}
                                                />
                                            )}{' '}
                                            Continue with {this.props.board.title}
                                        </a>
                                    </div>
                                </div>
                            )}
                            {!this.props.board.sso_login_exclusive &&
                                <>
                                    <div className="columns">
                                        <div className="column" style={{padding: "0.5rem"}}>
                                            <a className="is-fullwidth button is-google is-auth" href={`/login/social/google-oauth2?next=/`}><GoogleIcon /> Continue with Google</a>
                                        </div>
                                        <div className="column" style={{padding: "0.5rem"}}>
                                            <a className="is-fullwidth button is-facebook is-auth" href={`/login/social/facebook?next=/`}><FacebookIcon /> Continue with Facebook</a>
                                        </div>
                                    </div>
                                    <hr style={{height: "1px"}} />
                                    <div className="columns">
                                        <div className="column">
                                            <div className="has-text-centered">
                                                {this.state.signingUp ? <small>Or sign up with email</small> : <small>Or log in with email</small>}
                                            </div>
                                            <form onSubmit={this.handleSubmit} style={{marginTop: "1.5rem"}}>
                                                {this.state.signingUp && <div className="field">
                                                    <div className="control has-icons-right">
                                                        <input
                                                            type="text"
                                                            className="input"
                                                            id="name"
                                                            value={name}
                                                            placeholder="Full name"
                                                            onChange={this.handleChange}
                                                        />
                                                        <span className="icon is-small is-right">
                                                          <UserIcon />
                                                        </span>
                                                    </div>
                                                </div>}
                                                <div className="field">
                                                    <div className="control has-icons-right">
                                                        <input
                                                            type="email"
                                                            className="input"
                                                            id="email"
                                                            placeholder="Email"
                                                            value={email}
                                                            onChange={this.handleChange}
                                                        />
                                                        <span className="icon is-small is-right">
                                                          <EmailIcon />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="field">
                                                    <div className="control has-icons-right">
                                                        <input
                                                            type="password"
                                                            className="input"
                                                            id="password"
                                                            value={password}
                                                            placeholder="Password"
                                                            onChange={this.handleChange}
                                                        />
                                                        <span className="icon is-small is-right">
                                                          <PasswordIcon />
                                                        </span>
                                                    </div>
                                                </div>
                                                {this.props.error && (
                                                    <div className="error has-text-centered has-text-danger" style={{margin: "1rem auto"}}>
                                                        <small>{this.props.error}</small>
                                                    </div>
                                                )}
                                                <div className="has-text-right">
                                                    <button type="submit" disabled={!((name || !this.state.signingUp) && email && password)} className="button has-text-right is-primary" style={{color: "white", border: "none", background: this.props.board.color}}>
                                                        {this.state.signingUp ? "Sign up" : "Log in"}
                                                    </button>
                                                </div>
                                                <div className="has-text-centered" style={{opacity: "0.8", cursor: "pointer", marginTop: "1rem"}}>
                                                    {this.state.signingUp ?
                                                        <small onClick={this.toggleLoginSignup}>Already have an account? <strong>Log in here</strong></small>
                                                        :
                                                        <small onClick={this.toggleLoginSignup}>Don't have an account yet? <strong>Sign up here</strong></small>
                                                    }
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    )}
                 </div>
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login)
