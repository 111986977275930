import React from 'react'
import ReactDOM from 'react-dom'
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom'
import ModalSwitch from './components/ModalSwitch'
import * as serviceWorker from './serviceWorker'
import store from './store/index'
import { Provider } from 'react-redux'
import NotFound from './components/NotFound'
import * as Sentry from '@sentry/browser';
import BoardCreate from './components/admin/BoardCreate'


let nodeEnv = null
try{
    if(process.env.NODE_ENV === 'development'){
        nodeEnv = 'development'
    }
}
catch(e){
}


if(!nodeEnv){
    Sentry.init({
     dsn: "https://7e0a0796f6ee42f0849e17039ab2d7ab@sentry.io/1417561"
    });
}

// const PrivateRoute = ({ component: Component, ...rest }) => (
//   <Route {...rest} render={(props) => (
//     (store.getState().user && store.getState().user.uuid)
//       ? <Component {...props} />
//       : <Redirect to={{
//           pathname: '/login',
//           state: { from: props.location }
//         }} />
//   )} />
// )

const Root = ({ store }) => (
    <Provider store={store}>
        <Router>
            <Switch>
                <Route exact path="/404" component={NotFound} />
                <Route
                    exact
                    path="/product/create/"
                    component={BoardCreate}
                />
                <Route path="/product/:product_slug/" component={ModalSwitch} />
                <Route component={ModalSwitch} />
                <Route exact path="*" component={NotFound} />
            </Switch>
        </Router>
    </Provider>
)

ReactDOM.render(<Root store={store} />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
