import React, { Component } from 'react'
import { connect } from 'react-redux'
import AdminBase from './AdminBase'
import { Link } from 'react-router-dom'
import BoardSetTypesInner from './BoardSetTypesInner'
import { updateBoardTypes } from '../../actions/admin'

const mapStateToProps = (state, ownProps) => {
    return { board: state.rootReducer.board,
             user: state.rootReducer.user }
}

export class BoardTypeSettings extends Component {
    constructor(props) {
        super()
        this.postSubmit = this.postSubmit.bind(this)
    }
    postSubmit(){
        window.location.reload()
    }
    render() {
        return (
            <AdminBase tab="settings">
                <AdminBase.Header>
                <div className="tabs settings-tabs">
                    <ul>
                      <li><Link to={{pathname: `/product/${this.props.board.product.slug}/settings/`}}>Board style</Link></li>
                      <li className="is-active"><Link to={{pathname: `/product/${this.props.board.product.slug}/settings/types/`}}>Types</Link></li>
                      <li><Link to={{pathname: `/product/${this.props.board.product.slug}/settings/advanced/`}}>Advanced</Link></li>
                      <li><Link to={{pathname: `/product/${this.props.board.product.slug}/settings/team/`}}>Team & people</Link></li>
                    </ul>
                </div>
                </AdminBase.Header>
                <AdminBase.Body>
                    <div className="section">
                        <div className="container">
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-header-title">Feedback types</div>
                                </div>
                                <div className="card-content">
                                    <p>Which types of feedback do you want to collect?</p>
                                    <BoardSetTypesInner {...this.props} postSubmit={this.postSubmit} />
                                </div>
                            </div>
                        </div>
                    </div>
                </AdminBase.Body>
            </AdminBase>
        )
    }
}

export default connect(
    mapStateToProps,
    {updateBoardTypes}
)(BoardTypeSettings)
