import React, { Component } from 'react'
export class PostThumbPlaceholder extends Component {
    render() {
        return (
            <div className="board-feedback-item" style={{minHeight: "106px"}}>
                <div className="feedback-item-inner">
                    <div className="is-mobile is-vcentered feedback-post-thumb-column">
                    </div>
                </div>
            </div>
        )
    }
}

export default PostThumbPlaceholder
