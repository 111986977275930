import store from './store/index'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { emptyUser } from './actions/index'

const cookies = new Cookies()
const headers = {
    'X-CSRFToken': cookies.get('csrftoken'),
    Accept: 'application/json',
    'Content-Type': 'application/json',
}

const apiCall = axios.create({
    headers: headers,
})

apiCall.interceptors.response.use(
    function(response) {
        // Do something with response data
        return response
    },
    function(error) {
        if (error && error.response) {
            if (error.response.status === 403) {
                store.dispatch(emptyUser())
                window.location.href = '/404'
            }
            if (error.response.status === 404) {
                window.location.href = '/404'
            }
        }
        return Promise.reject(error)
    }
)

export { apiCall }
