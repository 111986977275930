import React, { Component } from 'react'
import Base from './../Base'
import { apiCall } from './../../utils'
import { RightArrowIcon } from './../icons/RightArrowIcon'

class BoardCreate extends Component {
    constructor() {
        super()
        this.state = {
            title: '',
            website: '',
            website_loaded: null,
            website_error: null,
            name_error: null,
            error: null,
            submitted: null,
        }
        this.handleNameChange = this.handleNameChange.bind(this)
        this.handleSiteChange = this.handleSiteChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handleNameChange(event) {
        this.setState({ title: event.target.value, name_error: null, error: null })
    }
    handleSiteChange(event) {
        this.setState({ website: event.target.value, website_error: null, error: null })
    }
    handleErrors(e){
        const newState = {submitted: null}
        if(e.response){
            try{
                newState['website_error'] = e.response.data.website[0]
            }catch(e){}
            try{
                newState['name_error'] = e.response.data.title[0]
            }catch(e){}
        }
        if(!newState.website_error && !newState.name_error){
            newState['error'] = "Something went wrong and that didn't work - please get in touch if this keeps happening"
        }
        console.log(newState)
        this.setState(newState)
    }
    handleSubmit(event) {
        event.preventDefault()
        this.setState({submitted: true})
        const { title, website } = this.state
        const self = this
        apiCall
            .post(`/_/api/v1/product/create/`, { title: title, website: website })
            .then(function(response) {
                self.props.history.push(`/product/${response.data.slug}/set-types/`)
            })
            .catch(function(e) {
                self.handleErrors(e)
            })
    }
    render() {
        const { title, website } = this.state
        return (
            <Base>
                <Base.Header>
                    <div style={{padding: ".5rem", borderBottom: "1px solid #eee"}} className="has-text-centered section">
                        <ul className="list-inline list-inline-middle">
                            <li style={{margin: 0}}>
                                <svg width="35px" height="35px" viewBox="0 0 35 35" version="1.1" style={{display: "block"}}>
                                    <defs>
                                        <linearGradient x1="42.2342062%" y1="-25.6534943%" x2="57.9396527%" y2="134.532563%" id="linearGradient-1">
                                            <stop stopColor="#4EB8A0" offset="0%"></stop>
                                            <stop stopColor="#2D6659" offset="100%"></stop>
                                        </linearGradient>
                                    </defs>
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <path d="M0.00358383423,17.8577428 C0.0011983979,17.7387827 0,17.6195313 0,17.5 C0,15.4953366 0.337070433,13.5694002 0.957626976,11.7757752 L12.1610169,18.2440554 L12.1610169,24.8768401 L0.00358383423,17.8577428 Z M0.179250554,20.0141396 L12.1610169,26.9318157 L12.1610169,34.170635 C5.87881473,32.1603357 1.14078908,26.6974003 0.179250554,20.0141396 Z M1.63217554,10.1102504 C2.40083026,8.46254921 3.41857214,6.95441179 4.63697786,5.63426151 L12.1610169,9.9782675 L12.1610169,16.1890799 L1.63217554,10.1102504 Z M5.88879177,4.4066191 C7.6871188,2.81069053 9.81653618,1.57959685 12.1610169,0.829365003 L12.1610169,7.94355183 L5.88879177,4.4066191 Z M13.940678,34.6377874 C15.0899354,34.8752404 16.2803981,35 17.5,35 C27.1649831,35 35,27.1649831 35,17.5 C35,7.83501688 27.1649831,0 17.5,0 C16.2803981,0 15.0899354,0.124759581 13.940678,0.362212622 L13.940678,34.6377874 Z" fill="url(#linearGradient-1)"></path>
                                    </g>
                                </svg>
                            </li>
                            <li><div style={{fontWeight: "600", color: "#333"}}>Dashvine</div></li>
                        </ul>
                    </div>
                </Base.Header>
                <Base.Body>
                    <div className="container">
                        <div className="section">
                            <div style={{maxWidth: "600px", margin: "auto"}}>
                                <h3 style={{marginBottom: "0.3rem"}}>Let's get you set up</h3>
                                <p style={{marginBottom: "2rem"}}>Tell us a bit about the product you're collecting feedback for</p>
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="field">
                                            <div style={{marginBottom: ".5rem"}}>
                                                <label style={{marginBottom: "0.1rem", textTransform: "uppercase"}} htmlFor="name" className="label">
                                                    Product Name
                                                </label>
                                                <div className="help" style={{opacity: "0.6"}}>What's your product called?</div>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="title"
                                                placeholder="e.g. Slack"
                                                value={title}
                                                onChange={this.handleNameChange}
                                            />
                                            {this.state.name_error && <div className="has-text-danger" style={{opacity: "0.6"}}>{this.state.name_error}</div>}
                                        </div>
                                        <div className="field" style={{marginTop: "2rem"}}>
                                            <div style={{marginBottom: ".5rem"}}>
                                                <label style={{marginBottom: "0.1rem", textTransform: "uppercase"}} htmlFor="name" className="label">
                                                    Website
                                                </label>
                                                <div className="help" style={{opacity: "0.6"}}>Where do people find your product online?</div>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="title"
                                                placeholder="e.g. slack.com"
                                                value={website}
                                                onChange={this.handleSiteChange}
                                                autoComplete="off"
                                            />
                                            {this.state.website_error && <div className="has-text-danger" style={{opacity: "0.6"}}>{this.state.website_error}</div>}
                                        </div>
                                        {(!this.state.website_loaded && this.state.website.length && this.state.title.length) ?
                                            <ul className="list-inline has-text-centered list-inline-middle">
                                                {!this.state.submitted &&
                                                    <li>
                                                        <small style={{fontSize: "14px", opacity: "0.7"}}>press <strong>ENTER</strong></small>
                                                    </li>
                                                }
                                                <li style={{marginRight: 0}}>
                                                    <button type="submit" className="button is-primary is-medium" disabled={this.state.submitted ? true : false}>
                                                        {this.state.submitted ?
                                                            <>Getting set up...</>
                                                            :
                                                            <>Continue <RightArrowIcon /></>
                                                        }

                                                    </button>
                                                </li>
                                            </ul>: ""

                                        }
                                        {this.props.error && (
                                            <div className="has-text-danger">{this.props.error}</div>
                                        )}
                                    </form>
                                </div>

                        </div>
                    </div>
                </Base.Body>
                <Base.Footer>
                    <div style={{height: "0.5rem", background: "#3A9F89", width: "25%", opacity: "0.6", position: "fixed", bottom: 0}}></div>
                </Base.Footer>
            </Base>
        )
    }
}

export default BoardCreate
