import React, { Component } from "react";
import Login from './Login'
import Post from './board/Post'
import { withRouter } from 'react-router'

class Modal extends Component {
    constructor() {
        super()
        this.back = this.back.bind(this)
    }
  back(e){
      e.stopPropagation();
      if(this.props.location.key){
          this.props.history.goBack();
      }else{
          if(this.props.match.params.product_slug){
              this.props.history.push(`/product/${this.props.match.params.product_slug}/feedback/`)
          }else{
              this.props.history.push('/')
          }
      }
  }
  render() {
      const match = this.props.match
      // console.log(match)
      let contents = null
      if(match.path === "/login/"){
          contents = <Login initialLogin={true} />
      }else if(match.path === "/signup/"){
          contents = <Login />
      }else if(match.path === "/p/:post_slug/" || match.path === "/product/:product_slug/p/:post_slug/"){
          contents = <Post post_slug={match.params.post_slug} />
      }

      return (
        <div className="react-router-modal__container">
            <div className="react-router-modal__wrapper">
                <div className="react-router-modal__backdrop react-router-modal__backdrop--in" onClick={this.back}>
                </div>
                <div className="react-router-modal__modal react-router-modal__modal--in" role="dialog" aria-modal="true">
                    {contents}
                </div>
            </div>
        </div>
      );
  }
}

export default withRouter(Modal);
