import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import rootReducer from '../reducers/index'
import loadingReducer from '../reducers/loading'
import errorReducer from '../reducers/errors'
import { forbiddenWordsMiddleware } from '../middleware'
import thunk from 'redux-thunk'

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const reducers = {
    rootReducer,
    loadingReducer,
    errorReducer,
}

const reducer = combineReducers(reducers)

const store = createStore(
    reducer,
    storeEnhancers(applyMiddleware(forbiddenWordsMiddleware, thunk))
)

export default store
