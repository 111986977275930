import React, { Component } from 'react'
import UpArrowNoBgIcon from './../icons/UpArrowNoBgIcon'


class VoteButton extends Component {
    constructor() {
        super()
        this.state = {
            expanded: false,
        }
        this.toggleVote = this.toggleVote.bind(this)
        this.expandButton = this.expandButton.bind(this)
        this.closeButton = this.closeButton.bind(this)
    }
    toggleVote(event, importance) {
        event.preventDefault()
        if (this.props.user && !this.props.user.uuid) {
            this.props.history.push({pathname: "/signup/", state: { modal: true }})
        } else {
            this.props.toggleVote(
                this.props.board.product.slug,
                this.props.post.uuid,
                importance
            ).then(function(){
                this.closeButton()
            }.bind(this))
        }
    }
    expandButton(event){
        event.preventDefault()
        if (this.props.user && !this.props.user.uuid) {
            this.props.history.push({pathname: "/signup/", state: { modal: true }})
        } else {
            this.setState({expanded: true})
        }
    }
    closeButton(event){
        if(event){
            event.preventDefault()
        }
        this.setState({expanded: false})
    }
    render() {
        const voteStyle = {height: "auto",
                           background: "transparent"}
        if (this.props.post && this.props.post.voted_on) {
            voteStyle['color'] = this.props.board.color
            voteStyle['borderColor'] = this.props.board.color
        }
        return (
            <>
                {this.state.expanded ?
                    <div className="select-importance" style={{border: `1px solid ${this.props.board.color}`, padding: "0.5rem", borderRadius: "4px", background: "white"}}>
                        <div style={{marginBottom: "0.5rem", marginRight: "2.5rem"}}><small style={{fontWeight: 600}}>How important is this to you?</small></div>
                        <small style={{position: "absolute", top: "0.5rem", right: "0.5rem", cursor: "pointer", fontSize: "12px", color: "#888"}} onClick={this.closeButton}>Close</small>
                        <div className="field is-grouped select-importance-buttons">
                          <div className="control">
                            <button onClick={(e) => this.toggleVote(e, 1)} className={`button is-rounded is-small ${(this.props.post.voted_on && this.props.post.voted_on.importance === 1) && "active"}`} style={(this.props.post.voted_on && this.props.post.voted_on.importance === 1) ? {borderColor: this.props.board.color, background: this.props.board.color} : {}}>
                              Nice to have
                            </button>
                          </div>
                          <div className="control">
                            <button onClick={(e) => this.toggleVote(e, 2)} className={`button is-rounded is-small ${(this.props.post.voted_on && this.props.post.voted_on.importance === 2) && "active"}`} style={(this.props.post.voted_on && this.props.post.voted_on.importance === 2) ? {borderColor: this.props.board.color, background: this.props.board.color} : {}}>
                              Important
                            </button>
                          </div>
                          <div className="control">
                            <button onClick={(e) => this.toggleVote(e, 3)} className={`button is-rounded is-small ${(this.props.post.voted_on && this.props.post.voted_on.importance === 3) && "active"}`} style={(this.props.post.voted_on && this.props.post.voted_on.importance === 3) ? {borderColor: this.props.board.color, background: this.props.board.color} : {}}>
                              Critical
                            </button>
                          </div>
                        </div>
                    </div>:
                    <div>
                        <div onClick={this.expandButton} className={`button vote-button ${this.props.post.voted_on && 'active'}`} style={voteStyle}>
                            {this.props.post.voted_on ? <span><UpArrowNoBgIcon size="25" color={this.props.board.color} /> Upvoted</span> : <span><UpArrowNoBgIcon size="25" color="#444" /> Upvote</span>}
                        </div>
                        {this.props.post.voted_on && <span style={{display: "inline-block", marginLeft: "0.3rem", fontSize: "12px", color: "#666", fontWeight: "400"}}>as <strong style={{color: "#666"}}>{this.props.post.voted_on.importance_string}</strong></span>}
                        {(this.props.post.can_edit && this.props.detail) && <button onClick={this.props.toggleEditing} className="button is-pulled-right is-small">Edit</button>}
                    </div>
                }
            </>

        )
    }
}

export default VoteButton
