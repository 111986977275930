import React, { Component } from 'react';


export class Avatar extends Component {
    render() {
        const avatarStyle = this.props.styleProp || {}
        if(this.props.color){
            avatarStyle['backgroundColor'] = this.props.color;
        }else{
            avatarStyle['backgroundColor'] = "#666";
        }
        if(this.props.user.avatar){
            avatarStyle['backgroundImage'] = `url('${this.props.user.avatar}')`;
        }
        return (
            <div
                style={avatarStyle}
                className="avatar-circle"
            >
                {(this.props.user.name && !this.props.user.avatar) && this.props.user.name
                    .toUpperCase()
                    .charAt(0)}
            </div>
        )
    }
}

export default Avatar
