import {
    GET_POSTS_SUCCESS,
    GET_SEARCH_POSTS_SUCCESS,
    GET_POST_SUCCESS,
    UPDATE_VOTE_SUCCESS,
    LOGGED_IN,
    GET_USER_SUCCESS,
    GET_MORE_POSTS_SUCCESS,
    CREATE_POST_SUCCESS,
    GET_BOARD_SUCCESS,
    CREATE_COMMENT_SUCCESS,
    UPDATE_BOARD_FORM_STATE,
    UPDATE_STATUS_SUCCESS,
    EMPTY_SEARCH,
    GET_DASHBOARD_POSTS_SUCCESS,
    EMPTY_BOARD,
    UPDATE_POST_SUCCESS,
    UPDATE_USER_SUCCESS,
    GET_MORE_DASHBOARD_POSTS_SUCCESS,
} from '../constants/action-types'

import merge from 'lodash/merge'

const initialState = {
    board: null,
    posts: null,
    created_posts: null,
    searched_posts: null,
    post: null,
    next: null,
    comments: null,
    user: null,
    identities: null,
    status: null,
    board_form_active: null,
    statuses: null,
}


function mergeNewComment(state, payload){
    // TODO
    // Check this hot mess out and improve it
    const comments = {...payload.comments}
    const obj = {}
    Object.keys(comments).forEach(function(key){
        obj['comment_uuid'] = key
        obj['post'] = comments[key].post_slug
        if(comments[key].parent_uuid){
             obj['parent'] = comments[key].parent_uuid
        }
    });
    const newState = merge({}, state, payload)
    if(newState.posts[obj.post] && obj.comment_uuid){
        newState.posts[obj.post].comments = [obj.comment_uuid, ...newState.posts[obj.post].comments]
    }
    if(newState.comments[obj.parent] && obj.comment_uuid){
        newState.comments[obj.parent].replies = [obj.comment_uuid, ...newState.comments[obj.parent].replies]
    }
    return newState
}

function getMorePosts(state, payload){
    const newState = merge({}, state, payload.entities)
    if(newState.statuses && newState.statuses[payload.status_uuid]){
        const new_results = [...state.statuses[payload.status_uuid].posts.results,
                             ...payload.entities.statuses[payload.status_uuid].posts.results]
        newState.statuses[payload.status_uuid].posts.results = new_results
    }
    return newState
}

function addNewPost(state, post){
    const payload = post.entities
    const post_slug = post.result
    let newState = null
    if (state.posts){
        newState = merge({}, payload, state)
    }else{
        newState = merge({}, state, payload)
    }
    try{
        const status_slug = payload.posts[post_slug].status.slug
        newState.board.statuses[status_slug].count = newState.board.statuses[status_slug].count + 1
    }
    catch(e){}
    // try{
    //     if(payload.posts[post_slug].status.is_shown){
    //         const status_uuid = payload.posts[post_slug].status.uuid
    //         if(newState.statuses[status_uuid]){
    //             newState.statuses[status_uuid].posts.results.unshift(post_slug)
    //         }
    //     }
    // }
    // catch(e){
    //     console.log(e)
    // }
    if(newState.board && newState.board.total_posts){
        newState.board.total_posts = newState.board.total_posts + 1
    }else{
        newState.board.total_posts = 1
    }
    return newState
}

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case GET_POST_SUCCESS:
            return merge({}, state, action.payload)
        case GET_POSTS_SUCCESS:
            return merge({}, state, action.payload)
        case GET_SEARCH_POSTS_SUCCESS:
            const searchResults = merge({}, state, action.payload.entities)
            return Object.assign({}, searchResults, {
                searched_posts: action.payload.result,
            })
        case GET_DASHBOARD_POSTS_SUCCESS:
            const newDashboardState =  Object.assign({}, state, {
                posts: null,
                next: null,
            })
            return merge({}, newDashboardState, action.payload)
        case GET_MORE_DASHBOARD_POSTS_SUCCESS:
            return merge({}, state, action.payload)
        case EMPTY_SEARCH:
            return Object.assign({}, state, {
                searched_posts: null,
            })
        case GET_MORE_POSTS_SUCCESS:
            return getMorePosts(state, action.payload)
        case CREATE_POST_SUCCESS:
            return addNewPost(state, action.payload)
        case CREATE_COMMENT_SUCCESS:
            return mergeNewComment(state, action.payload)
        case GET_BOARD_SUCCESS:
            return Object.assign({}, state, {
                board: action.payload,
                user: action.payload.identity,
            })
        case EMPTY_BOARD:
            return initialState
        case UPDATE_BOARD_FORM_STATE:
            return Object.assign({}, state, {
                board_form_active: action.payload,
            })
        case UPDATE_VOTE_SUCCESS:
            return merge({}, state, action.payload)
        case UPDATE_USER_SUCCESS:
            return Object.assign({}, state, {
                user: action.payload,
            })
        case UPDATE_POST_SUCCESS:
            return merge({}, state, action.payload)
        case UPDATE_STATUS_SUCCESS:
            // const newStateToClear = merge({}, state, action.payload)
            // newStateToClear.statuses = null
            // newStateToClear.board = null
            return state
        case LOGGED_IN:
            return Object.assign({}, state, {
                user: action.payload,
            })
        case GET_USER_SUCCESS:
            return Object.assign({}, state, {
                user: action.payload,
            })
        default:
            return state
    }
}

export default rootReducer
