import React, { Component } from 'react';
import Avatar from './Avatar'


export class AvatarAndName extends Component {
    render() {
        return (
                <>
                    {(this.props.identities && this.props.identities[this.props.user]) &&
                        <strong>
                            <Avatar user={this.props.identities[this.props.user]} color={this.props.color} /> {this.props.identities[this.props.user].name}
                        </strong>
                    }
                </>
        )
    }
}

export default AvatarAndName
