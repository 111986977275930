import React, { Component } from 'react'
import AdminBase from './../AdminBase'

export class User extends Component {
    render() {
        return (
            <AdminBase tab="users">
                <AdminBase.Header>
                    User
                </AdminBase.Header>
            </AdminBase>
        )
    }
}

export default User
