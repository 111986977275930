import React, { Component } from 'react'
const ReactMarkdown = require('react-markdown')


class PreviewComment extends Component {
    render() {
        return (
            <div>
                <div className="comment-preview comment" style={{background: this.props.board.bg_color}}>
                    <small style={{fontWeight: 700, opacity: "0.7"}}>{this.props.comment.user.name}</small> <small style={{opacity: "0.7", fontSize: "10px"}}>{this.props.comment.human_created}</small>
                    <div className="comment-preview-meta comment-body" style={{marginTop: ".2rem"}}>
                        <span className="comment-preview-tip-up" style={{borderTopColor: this.props.board.bg_color}}></span>
                        <ReactMarkdown
                            linkTarget="_blank"
                            source={this.props.comment.content}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default PreviewComment
