import React, { Component } from 'react'
import { createComment } from '../../../actions/index'
import { connect } from 'react-redux'
import Comment from './Comment'
import CommentForm from './CommentForm';

export class Comments extends Component {
    render() {
        return (
            <div>
                <CommentForm {...this.props} />
                {(this.props.comments) && (
                    <div>
                        {this.props.post.comments.map(comment_uuid => (
                            <div key={comment_uuid}>
                                {(!this.props.comments[comment_uuid].parent_uuid) &&
                                    <Comment {...this.props}
                                        comment={this.props.comments[comment_uuid]}
                                    />
                                }
                            </div>
                        ))}
                    </div>
                )}
            </div>
        )
    }
}

export default connect(
    null,
    { createComment }
)(Comments)
