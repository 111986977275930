import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Avatar from './../Avatar'
import ExternalWindow from './../icons/ExternalWindow'


class BoardNav extends Component {
    constructor() {
        super()
        this.state = {
            title: '',
            description: '',
            avatarDroppedDown: false,
        }
        this.toggleAvatarDropdown = this.toggleAvatarDropdown.bind(this)
    }
    toggleAvatarDropdown(event) {
        event.preventDefault()
        this.setState({ avatarDroppedDown: !this.state.avatarDroppedDown })
    }
    render() {
        let avatarDropdownClass = ''
        if (this.state.avatarDroppedDown) {
            avatarDropdownClass = 'is-active'
        }
        return (
            <nav
                className="navbar board-navbar"
                role="navigation"
                aria-label="main navigation"
            >
                <div className="board-navbar-menu">
                    <div className="navbar-brand">
                        {this.props.board && (
                            <a
                                className="navbar-item"
                                href={this.props.board.website}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    marginLeft:
                                        '0',
                                }}
                            >
                                {this.props.board.logo && (
                                    <img
                                        style={{
                                            marginRight:
                                                '0.5rem',
                                        }}
                                        src={
                                            this.props.board
                                                .logo
                                        }
                                        alt={`${
                                            this.props.board
                                                .title
                                        } logo`}
                                    />
                                )}{' '}
                                <span className={this.props.board.logo && "is-hidden-mobile"}>
                                    {this.props.board.title}
                                </span>
                                <span style={{marginLeft: "0.2rem", verticalAlign: "middle"}}>
                                    <ExternalWindow />
                                </span>
                            </a>
                        )}
                    </div>
                    {!this.props.is_admin && (
                        <div className="navbar-end">
                            {this.props.user &&
                            this.props.user.uuid ? (
                                <div
                                    className={`dropdown is-right board-login-button navbar-item ${avatarDropdownClass}`}
                                >
                                    <div className="dropdown-trigger" onClick={this.toggleAvatarDropdown} style={{cursor: "pointer"}}>
                                        <Avatar color={this.props.board.color} user={this.props.user} />
                                    </div>
                                    <div
                                        className="dropdown-menu"
                                        id="dropdown-menu"
                                        role="menu"
                                    >
                                        <div className="dropdown-content">
                                            {(this.props.user && this.props.user.is_admin) &&
                                                <>
                                                    <a
                                                        href={this.props.board.dashboard_redirect_url}
                                                        className="dropdown-item"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Dashboard
                                                        <span style={{marginLeft: "0.2rem", verticalAlign: "middle"}}>
                                                            <ExternalWindow />
                                                        </span>
                                                    </a>
                                                    <hr className="navbar-divider" />
                                                </>
                                            }
                                            <a
                                                href="/logout"
                                                className="dropdown-item"
                                            >
                                                Logout
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="navbar-item">
                                    <div className="buttons">
                                        <Link
                                            to={{
                                              pathname: "/login/",
                                              // this is the trick!
                                              state: { modal: true }
                                            }}
                                            className="button is-small"
                                         >
                                            Log in
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </nav>
        )
    }
}

export default BoardNav
