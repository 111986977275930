import React, { Component } from 'react'


export class ChatIcon extends Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" className="icon-chat" style={{verticalAlign: "middle", marginRight: "0.1rem"}}>
                <path fill="#aaa" d="M2 15V5c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v15a1 1 0 0 1-1.7.7L16.58 17H4a2 2 0 0 1-2-2z"/>
                <path fill="white" d="M6 7h12a1 1 0 0 1 0 2H6a1 1 0 1 1 0-2zm0 4h8a1 1 0 0 1 0 2H6a1 1 0 0 1 0-2z"/>
            </svg>
        )
    }
}

export default ChatIcon
