import React, { Component } from 'react'
import ExternalWindow from './../icons/ExternalWindow'
import PasswordIcon from './../icons/PasswordIcon'
import UpArrowNoBgIcon from './../icons/UpArrowNoBgIcon'


class BoardStylePreview extends Component {
    render() {
        const voteStyle = {height: "auto",
                           fontSize:"6px",
                           background: "transparent",
                           padding: "0.2rem 0.3rem"}
        return (
            <div className="card board-preview-card">
                <div className="card-content" style={{padding: ".3rem"}}>
                    <div className="card" style={{boxShadow: "none", border: "1px solid #eee", margin: 0}}>
                        <div className="card-header" style={{background: "#fafafa"}}>
                            <small style={{fontSize: "10px", padding: "0.2rem 0.5rem"}}><span style={{opacity: "0.6", verticalAlign: "middle"}}><PasswordIcon size="10" color="green" /></span><span style={{color: "green", marginLeft: "0.1rem"}}>https://</span><strong>{this.props.subdomain}</strong>.dashvine.com</small>
                        </div>
                        <div className="card-header" style={{boxShadow: "none", marginLeft: "0.3rem"}}>
                            {(this.props.temp_logo || this.props.logo) &&
                                <img alt={`${this.props.board.product.title} logo`} style={{maxHeight: "1rem", marginTop: ".66rem"}} src={this.props.temp_logo || this.props.logo} />
                            }
                            <h4 className="card-header-title" style={{fontSize: "10px", paddingLeft: "0.3rem"}}>{this.props.board.product.title}
                                <span style={{marginLeft: "0.2rem", verticalAlign: "middle"}}>
                                    <ExternalWindow size="10" />
                                </span>
                            </h4>
                        </div>
                        <div className="card-content" style={{padding: "0.3rem", paddingTop: 0}}>
                            <div className="board-wrapper" style={{paddingBottom: 0, paddingTop: "1rem"}}>
                                <div className="hero has-text-centered is-white board-hero">
                                      <div className="hero-body" style={{paddingTop: 0}}>
                                         <div>
                                           <h2 className="subtitle" style={{fontSize: "12px"}}>
                                             {this.props.description || "Share your feedback or vote on other people's ideas"}
                                           </h2>
                                         </div>
                                     </div>
                                     <div className="board-form-click-input-wrapper" style={{border: `1px solid ${this.props.color}`}}>
                                         <div className="content">
                                             <div className="board-form-click-input has-text-centered" style={{fontSize: "10px", padding: "0.2rem 0.5rem"}}>
                                                 + Share a new suggestion or request
                                             </div>
                                          </div>
                                     </div>
                                </div>
                                <div style={{marginTop: "2rem"}}>
                                    <div>
                                        <div className="board" style={{margin: 0}}>
                                            <div className="board-feedback-item">
                                                <div className="feedback-item-inner">
                                                    <div className="is-mobile is-vcentered feedback-post-thumb-column">
                                                        <div>
                                                            <div style={{width: "100%", height: "0.8rem", background: "#ccc", marginBottom: "0.3rem"}}></div>
                                                            <div style={{marginTop: "0.5rem"}}>
                                                                <div className="button vote-button" style={voteStyle}>
                                                                    <UpArrowNoBgIcon height="7" width="5" /> Upvote
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="board-feedback-item">
                                                <div className="feedback-item-inner">
                                                    <div className="is-mobile is-vcentered feedback-post-thumb-column">
                                                        <div>
                                                            <div style={{width: "60%", height: "0.8rem", background: "#ccc", marginBottom: "0.3rem"}}></div>
                                                            <div style={{marginTop: "0.5rem"}}>
                                                                <div className="button vote-button" style={{...voteStyle, color: this.props.color, borderColor: this.props.color}}>
                                                                    <UpArrowNoBgIcon height="7" width="5" color={this.props.color} /> Upvoted
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="board" style={{margin: 0}}>
                                            <div className="board-feedback-item">
                                                <div className="feedback-item-inner">
                                                    <div className="is-mobile is-vcentered feedback-post-thumb-column">
                                                        <div>
                                                            <div style={{width: "100%", height: "0.8rem", background: "#ccc", marginBottom: "0.3rem"}}></div>
                                                            <div style={{marginTop: "0.5rem"}}>
                                                                <div className="button vote-button" style={{...voteStyle, color: this.props.color, borderColor: this.props.color}}>
                                                                    <UpArrowNoBgIcon height="7" width="5" color={this.props.color} /> Upvoted
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="board-feedback-item">
                                                <div className="feedback-item-inner">
                                                    <div className="is-mobile is-vcentered feedback-post-thumb-column">
                                                        <div>
                                                            <div style={{width: "90%", height: "0.8rem", background: "#ccc", marginBottom: "0.3rem"}}></div>
                                                            <div style={{marginTop: "0.5rem"}}>
                                                                <div className="button vote-button" style={voteStyle}>
                                                                    <UpArrowNoBgIcon height="7" width="5" /> Upvote
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="board" style={{margin: 0}}>
                                            <div className="board-feedback-item">
                                                <div className="feedback-item-inner">
                                                    <div className="is-mobile is-vcentered feedback-post-thumb-column">
                                                        <div>
                                                            <div style={{width: "60%", height: "0.8rem", background: "#ccc", marginBottom: "0.3rem"}}></div>
                                                            <div style={{marginTop: "0.5rem"}}>
                                                                <div className="button vote-button" style={voteStyle}>
                                                                    <UpArrowNoBgIcon height="7" width="5" /> Upvote
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="board-feedback-item">
                                                <div className="feedback-item-inner">
                                                    <div className="is-mobile is-vcentered feedback-post-thumb-column">
                                                        <div>
                                                            <div style={{width: "100%", height: "0.8rem", background: "#ccc", marginBottom: "0.3rem"}}></div>
                                                            <div style={{marginTop: "0.5rem"}}>
                                                                <div className="button vote-button" style={voteStyle}>
                                                                    <UpArrowNoBgIcon height="7" width="5" /> Upvote
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BoardStylePreview
