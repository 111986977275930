import { ADD_POST } from '../constants/action-types'
import { addErrorMessage } from '../actions/index'

const forbiddenWords = ['spam', 'money']

export function forbiddenWordsMiddleware({ dispatch }) {
    return function(next) {
        return function(action) {
            // do your stuff
            if (action.type === ADD_POST) {
                const foundWord = forbiddenWords.filter(word =>
                    action.payload.title.includes(word)
                )
                if (foundWord.length) {
                    return dispatch(
                        addErrorMessage({ error: "Don't use this word" })
                    )
                }
            }
            return next(action)
        }
    }
}
