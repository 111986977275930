import React, { Component } from 'react'
import { connect } from 'react-redux'
import {getBoard} from '../../actions/index'
import ColorPicker from '../ColorPicker'
import { apiCall } from './../../utils'
import BoardStylePreview from './BoardStylePreview'
import { CheckIcon } from './../icons/CheckIcon'
import { PhotoIcon } from './../icons/PhotoIcon'
import { withRouter } from 'react-router'

const hexColorRegex = require('hex-color-regex')


const mapStateToProps = (state, ownProps) => {
    return {
        board: state.rootReducer.board,
        user: state.rootReducer.user,
    }
}

class BoardSetStyleInner extends Component {
    constructor(props) {
        super()
        this.state = {
            subdomain: props.board.subdomain,
            logo: props.board.logo,
            temp_logo: null,
            logo_file: null,
            color: props.board.color,
            description: props.board.description,
            subdomain_error: null,
            error: null,
            submitted: null,
        }
        this.handleSubdomainChange = this.handleSubdomainChange.bind(this)
        this.handleColorChange = this.handleColorChange.bind(this)
        this.handleColorChangeInput = this.handleColorChangeInput.bind(this)
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this)
        this.handleLogoChange = this.handleLogoChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleErrors = this.handleErrors.bind(this)
    }
    componentDidMount() {
        this.props.getBoard(this.props.match.params.product_slug)
    }
    handleSubdomainChange(event) {
        this.setState({ subdomain: event.target.value, subdomain_error: null, error: null })
    }
    handleColorChange(e, color) {
        e.preventDefault()
        this.setState({ color: color, error: null })
    }
    handleColorChangeInput(e){
        this.setState({ color: e.target.value, error: null })
    }
    handleDescriptionChange(event) {
        this.setState({ description: event.target.value, error: null })
    }
    handleLogoChange(event) {
        const file = event.target.files[0]
        const temp_url = URL.createObjectURL(event.target.files[0])
        this.setState({temp_logo: temp_url, logo_file: file, error: null});
    }
    handleErrors(e){
        const newState = {submitted: null}
        if(e.response){
            try{
                newState['subdomain_error'] = "This subdomain is not available, please try a different one"
            }catch(e){}
        }
        if(!newState.subdomain_error){
            newState['error'] = "Something went wrong and that didn't work - please get in touch if this keeps happening"
        }
        console.log(newState)
        this.setState(newState)
    }
    handleSubmit(event) {
        event.preventDefault()
        this.setState({submitted: true})
        const data = new FormData();
        let validated_color = this.state.color
        if(!hexColorRegex({strict: true}).test(this.state.color)){
            validated_color = '#3A9F89'
        }
        if(this.state.logo_file){
            data.append('logo', this.state.logo_file);
        }
        data.append('subdomain', this.state.subdomain);
        data.append('color', validated_color);
        data.append('description', this.state.description);
        const self = this
        apiCall.patch(`/_/api/v1/product/${this.props.board.product.slug}/board/style/update/`, data)
            .then(function(response) {
                window.location = `/product/${response.data.product_slug}/feedback/`
            })
            .catch(function(e) {
                self.handleErrors(e)
            })
    }
    render() {
        return (
            <div className="section" style={{paddingTop: "2rem"}}>
                <div className="container">
                    <div className="card board-style-editor">
                        <div className="card-content">
                            <form className="form" onSubmit={this.handleSubmit}>
                                <div className="columns">
                                    <div className="column">
                                        <h3 style={{marginBottom: ".2rem"}}>Make it your own</h3>
                                        <p>Add some style so your customers feel at home when giving feedback</p>
                                        <div className="fancy-field">
                                            <label className="label">Feedback URL</label>
                                            <div className="field has-addons">
                                                <div className="control">
                                                    <input className="input has-text-right" onChange={this.handleSubdomainChange} type="text" placeholder="subdomain" value={this.state.subdomain} />
                                                </div>
                                                <div className="control">
                                                    <div className="button is-static">.dashvine.com</div>
                                                </div>
                                            </div>
                                            {this.state.subdomain_error && <div className="has-text-danger" style={{opacity: "0.6"}}>{this.state.subdomain_error}</div>}
                                            <div className="help" style={{opacity: "0.6"}}>This is where people will find your feedback board. You can also set up a custom URL at a later stage</div>
                                        </div>
                                        <div className="fancy-field">
                                            <div className="field">
                                                <label className="label">Introduction message</label>
                                                <div className="control">
                                                    <textarea className="textarea" rows="2" placeholder="Let people know what this board is for and what type of feedback you're after" onChange={this.handleDescriptionChange} value={this.state.description} />
                                                </div>
                                            </div>
                                            <div className="help" style={{opacity: "0.6"}}>Let people know what to expect here and what type of feedback you're after. Leave this blank to use our default message.</div>
                                        </div>
                                        <div className="fancy-field">
                                            <div className="field">
                                              <label className="file-label">
                                                <input className="file-input" type="file" name="logo" accept="image/gif, image/jpeg, image/png" onChange={this.handleLogoChange} />
                                                <span className="file-cta" style={{padding: "0.8rem 1rem", height: "auto", width: "100%"}}>
                                                  <span className="file-icon" style={{width: "2rem"}}>
                                                      {(this.state.logo || this.state.temp_logo) ?
                                                          <img src={this.state.temp_logo || this.state.logo} alt="Your product logo" /> :
                                                          <div style={{background: "white", width: "2rem", height: "2rem", borderRadius: "4px"}}>
                                                              <PhotoIcon size="32" />
                                                          </div>
                                                      }
                                                  </span>
                                                  <span className="file-label">
                                                    Select logo
                                                  </span>
                                                </span>
                                              </label>
                                              <div className="help" style={{opacity: "0.6"}}>PNG, JPEG, or GIF, ideally 100px by 100px</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="column">
                                        <label className="label" style={{marginBottom: 0}}>Preview</label>
                                        <div className="help" style={{opacity: "0.6", marginBottom: "0.5rem", marginTop: 0}}>This is a preview of your feedback board</div>
                                        <BoardStylePreview {...this.props} {...this.state} />
                                        <div className="field">
                                            <label className="label" style={{marginBottom: 0}}>Brand color</label>
                                            <div className="help" style={{opacity: "0.6", marginBottom: "0.5rem", marginTop: 0}}><strong>Hint:</strong> Use a color that is easy to read on a white background</div>
                                            <ColorPicker keep_color={this.props.board.color} default_color={this.state.color} handleColorChange={this.handleColorChange} handleColorChangeInput={this.handleColorChangeInput} />
                                        </div>
                                    </div>
                                </div>
                                <div className="field has-text-centered">
                                    <button className="button is-primary" type="submit" disabled={this.state.submitted}>
                                        {this.state.submitted ?
                                            <>Creating board...</>
                                            :
                                            <>Done <CheckIcon size={25} /></>
                                        }

                                    </button>
                                </div>
                                {this.props.error && (
                                    <div className="has-text-danger">{this.props.error}</div>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(
        mapStateToProps,
        { getBoard }
    )(BoardSetStyleInner))
