import {normalize} from 'normalizr'
import * as schema from './schema'
import { GET_DASHBOARD_POSTS_REQUEST,
         GET_DASHBOARD_POSTS_SUCCESS,
         GET_BOARD_SUCCESS, UPDATE_USER_SUCCESS,
         GET_MORE_DASHBOARD_POSTS_REQUEST,
         GET_MORE_DASHBOARD_POSTS_SUCCESS } from "../constants/action-types";
import {apiCall} from './../utils';

const data_to_slug_index = function(data){
    return data.reduce(function(map, obj){
                           map[obj.slug] = obj;
                           return map;
                          }, {});
}

export function getDashboardPosts(data={}) {
    return function(dispatch) {
        let url = `/_/api/v1/product/${data.product_slug}/board/dashboard/posts/`;
        if(data.sort || data.status || data.type){
            const q = {}
            q['sort'] = data.sort
            q['status'] = data.status
            q['type'] = data.type
            const qs = require('qs')
            const string_query = qs.stringify(q, { addQueryPrefix: true });
            url = url + string_query
        }
        dispatch({ type: GET_DASHBOARD_POSTS_REQUEST });
        return apiCall.get(url)
          .then(response => {
               const posts = normalize(response.data.results, [schema.post])
               posts.entities['next'] = response.data.next
               dispatch({ type: GET_DASHBOARD_POSTS_SUCCESS, payload: posts.entities });
          })
      };
}


export function getMoreDashboardPosts(next) {
    return function(dispatch) {
        dispatch({ type: GET_MORE_DASHBOARD_POSTS_REQUEST });
        return apiCall.get(next)
          .then(response => {
               const posts = normalize(response.data.results, [schema.post])
               posts.entities['next'] = response.data.next
               dispatch({ type: GET_MORE_DASHBOARD_POSTS_SUCCESS, payload: posts.entities });
          })
      };
}

export function updateBoardTypes(product_slug, types){
    return function(dispatch) {
        return apiCall.post(`/_/api/v1/product/${product_slug}/board/update-types/`, {"types": types})
          .then(response => {
              if(response.data.statuses){
                  response.data.statuses = data_to_slug_index(response.data.statuses)
              }
              if(response.data.types){
                  response.data.types = data_to_slug_index(response.data.types)
              }
              response.data.identity = JSON.parse(response.headers.identity);
              dispatch({ type: GET_BOARD_SUCCESS, payload: response.data });
          })
      };
}


export function dismissOnboardingMessageOne(product_slug){
    return function(dispatch) {
        return apiCall.patch(`/_/api/v1/product/${product_slug}/board/onboarding/message-one/dismiss/`, {'onboarding_message_one_seen': true})
          .then(response => {
              dispatch({ type: UPDATE_USER_SUCCESS, payload: response.data });
          })
      };
}
