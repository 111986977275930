import React, { Component } from 'react'
import PostThumb from './PostThumb'
import PostThumbPlaceholder from './PostThumbPlaceholder'

class BoardCard extends Component {
    constructor() {
        super()
        this.getMorePosts = this.getMorePosts.bind(this)
        this.handleScroll = this.handleScroll.bind(this)
    }
    componentDidMount() {
        if(!this.props.modal && (!this.props.statuses || !this.props.statuses[this.props.status.uuid])){
             window.addEventListener("scroll", this.handleScroll);
            this.props.getStatusBoard(this.props.board.product.slug, this.props.status.uuid)
        }
    }
    componentWillUnmount() {
      window.removeEventListener("scroll", this.handleScroll);
    }
    getMorePosts() {
        if(!this.props.isFetching && this.props.statuses && this.props.statuses[this.props.status.uuid] && this.props.statuses[this.props.status.uuid].posts.next){
            this.props.getMorePosts(this.props.statuses[this.props.status.uuid].posts.next, this.props.status.uuid)
        }
    }
    handleScroll(e){
        if(window.innerWidth > 1087){
            const rect = this.boardElement.getBoundingClientRect();
            const elemBottom = rect.bottom;
            if(elemBottom - 250 < window.innerHeight){
                this.getMorePosts()
            }
        }
    }
    componentDidUpdate(prevProps) {
      // Typical usage (don't forget to compare props):
      if(this.props.bottom_reached && (this.props.bottom_reached !== prevProps.bottom_reached) && window.innerWidth > 1087) {
        this.getMorePosts()
      }
    }
    render() {
        let posts = null
        if(this.props.statuses && this.props.statuses[this.props.status.uuid]){
            if(this.props.statuses[this.props.status.uuid].posts && this.props.statuses[this.props.status.uuid].posts.results){
                posts = this.props.statuses[this.props.status.uuid].posts.results
            }
        }
        let canGetMore = null
        if(this.props.statuses && this.props.statuses[this.props.status.uuid] && this.props.statuses[this.props.status.uuid].posts.next){
            canGetMore = true
        }
        return (
            <div className="board-inner" ref={ (boardElement) => this.boardElement = boardElement}>
                <div className="board-body">
                    {posts ?
                        <div>
                            {posts.map(post_uuid => (
                                <PostThumb
                                    key={post_uuid}
                                    post={this.props.posts[post_uuid]}
                                />
                            ))}
                        </div> :
                        <div>
                            <PostThumbPlaceholder />
                            <PostThumbPlaceholder />
                            <PostThumbPlaceholder />
                            <PostThumbPlaceholder />
                        </div>
                    }
                    {(canGetMore && !this.props.isFetching) && <button onClick={this.getMorePosts} disabled={this.props.isFetching} className="button is-fullwidth is-small card-load-more-button">Load more</button>}
                </div>
            </div>
        )
    }
}

export default BoardCard
