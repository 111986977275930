import { ADD_POST } from "../constants/action-types";
import {normalize} from 'normalizr'
import * as schema from './schema'
import { FOUND_BAD_WORD,
         UPDATE_VOTE_SUCCESS,
         GET_USER_SUCCESS,
         GET_POSTS_SUCCESS,
         GET_MORE_POSTS_SUCCESS,
         SIGNUP_REQUEST,
         SIGNUP_SUCCESS,
         SIGNUP_FAILURE,
         GET_POST_SUCCESS,
         GET_BOARD_SUCCESS,
         GET_POSTS_REQUEST,
         GET_MORE_POSTS_REQUEST,
         CREATE_POST_REQUEST,
         CREATE_COMMENT_REQUEST,
         CREATE_COMMENT_SUCCESS,
         CREATE_POST_SUCCESS,
         UPDATE_BOARD_FORM_STATE,
         CREATE_POST_FAILURE,
         UPDATE_STATUS_REQUEST,
         GET_SEARCH_POSTS_REQUEST,
         GET_SEARCH_POSTS_SUCCESS,
         EMPTY_SEARCH,
         EMPTY_BOARD,
         UPDATE_POST_SUCCESS } from "../constants/action-types";

import {apiCall} from './../utils';

const data_to_slug_index = function(data){
    return data.reduce(function(map, obj){
                           map[obj.slug] = obj;
                           return map;
                          }, {});
}


export function addPost(payload) {
  return { type: ADD_POST, payload }
};


export function addErrorMessage(payload) {
  return { type: FOUND_BAD_WORD, payload }
};


export function getBoard(product_slug=null) {
    let url = "/_/api/v1/board/";
    if(product_slug){
        url = `/_/api/v1/product/${product_slug}/board/`;
    }
    return function(dispatch) {
        return apiCall.get(url)
          .then(response => {
              if(response.data.statuses){
                  response.data.statuses = data_to_slug_index(response.data.statuses)
              }
              if(response.data.types){
                  response.data.types = data_to_slug_index(response.data.types)
              }
              response.data.identity = JSON.parse(response.headers.identity);
              dispatch({ type: GET_BOARD_SUCCESS, payload: response.data });
          })
      };
}

export function getPostSearch(product_slug, query){
    return function(dispatch) {
        const url = `/_/api/v1/product/${product_slug}/board/posts/?q=${query}`;
        dispatch({ type: GET_SEARCH_POSTS_REQUEST });
        return apiCall.get(url)
          .then(response => {
               const posts = normalize(response.data.results, [schema.post])
               dispatch({ type: GET_SEARCH_POSTS_SUCCESS, payload: posts });
          })
    }
}

export function getPosts(data={}) {
    return function(dispatch) {
        let url = `/_/api/v1/product/${data.product_slug}/board/posts/`;
        const query = {}
        if(data.status){
            query['status'] = data.status
            const qs = require('qs');
            const query_string = qs.stringify(query);
            url = `${url}?${query_string}`;
        }
        dispatch({ type: GET_POSTS_REQUEST });
        return apiCall.get(url)
          .then(response => {
               const posts = normalize(response.data.results, [schema.post])
               const obj = {'posts': posts,
                            'next': response.data.next}
               dispatch({ type: GET_POSTS_SUCCESS, payload: obj });
          })
      };
}

export function getStatusBoard(product_slug, status_uuid){
    return function(dispatch) {
        let url = `/_/api/v1/product/${product_slug}/board/status/${status_uuid}`;
        dispatch({ type: GET_POSTS_SUCCESS });
        return apiCall.get(url)
          .then(response => {
               const status = normalize(response.data, schema.status)
               dispatch({ type: GET_POSTS_SUCCESS, payload: status.entities });
          })
    }
}

export function clearPosts() {
    const obj = {'posts': null,
                 'count': null,
                 'next': null}
    return { type: GET_POSTS_SUCCESS, payload: obj };
}

export function getMorePosts(next_url, status_uuid) {
    return function(dispatch) {
        dispatch({ type: GET_MORE_POSTS_REQUEST });
        return apiCall.get(next_url)
          .then(response => {
              const status = normalize(response.data, schema.status)
              dispatch({ type: GET_MORE_POSTS_SUCCESS, payload: {'entities': status.entities, 'status_uuid': status_uuid} });
          })
      };
}


export function getPost(product_slug, post_slug) {
    return function(dispatch) {
        return apiCall.get(`/_/api/v1/product/${product_slug}/board/posts/p/${post_slug}/`)
          .then(response => {
              const post = normalize(response.data, schema.post)
              dispatch({ type: GET_POST_SUCCESS, payload: post.entities });
          })
      };
}

export function toggleVote(product_slug, uuid, importance) {
    return function(dispatch) {
        return apiCall.post(`/_/api/v1/product/${product_slug}/board/posts/p/${uuid}/vote/`, {"importance": importance})
          .then(response => {
              const post = normalize(response.data, schema.post)
              dispatch({ type: UPDATE_VOTE_SUCCESS, payload: post.entities });
          })
    };
}

export function updatePost(product_slug, uuid, new_post) {
    return function(dispatch) {
        return apiCall.patch(`/_/api/v1/product/${product_slug}/board/posts/p/${uuid}/update/`, new_post)
          .then(response => {
              const post = normalize(response.data, schema.post)
              dispatch({ type: UPDATE_POST_SUCCESS, payload: post.entities });
          })
    };
}


export function updateStatus(product_slug, post_uuid, data){
    return function(dispatch) {
        dispatch({ type: UPDATE_STATUS_REQUEST });
        return apiCall.post(`/_/api/v1/product/${product_slug}/board/posts/p/${post_uuid}/update-status/`, {'status_uuid': data.new_status, 'comment_body': data.comment_body})
          .then(response => {
              window.location.reload()
               // const post = normalize(response.data, schema.post)
               // dispatch({ type: UPDATE_STATUS_SUCCESS, payload: post.entities });
          })
      };
}

export function createPost(url, data) {
    return function(dispatch) {
        dispatch({ type: CREATE_POST_REQUEST });
        return apiCall.post(url, data)
           .then(function (response) {
               // Figure out what to do if someone adds a post to a different type that's loaded,
               // could seem wierd to add it to the top of that list
              const post = normalize(response.data, schema.post)
              dispatch({ type: CREATE_POST_SUCCESS, payload: post })
              dispatch({ type: UPDATE_BOARD_FORM_STATE, payload: false });
              return(response.data);
         })
         .catch(function (e) {
            dispatch({ type: CREATE_POST_FAILURE, payload: {'message': "Something went wrong"}, error: true});
         });
      };
}

export function createComment(url, data) {
    return function(dispatch) {
        dispatch({ type: CREATE_COMMENT_REQUEST });
        return apiCall.post(url, data)
           .then(function (response) {
               // TODO add post to local store (just add it to the top?)
              const comment = normalize(response.data, schema.comment)
              dispatch({ type: CREATE_COMMENT_SUCCESS, payload: comment.entities });
         })
         .catch(function (e) {
            console.log(e)
         });
      };
}

export function login(data) {
    return function(dispatch) {
        dispatch({ type: SIGNUP_REQUEST });
        const loginData = {}
        let url = '/_/api/v1/login/'
        loginData['email'] = data.login_data.email
        loginData['password'] = data.login_data.password
        if(data.login_data.signingUp){
            loginData['name'] = data.login_data.name
            url = '/_/api/v1/signup/'
        }
        return apiCall.post(url, loginData)
          .then(response => {
              dispatch({ type: SIGNUP_SUCCESS });
              window.location.href = data.next_url || "/";
               // dispatch({ type: LOGGED_IN, payload: response.data });
          }).catch(function (e) {
              // TODO better errors
             dispatch({ type: SIGNUP_FAILURE, payload: {'message': "Something went wrong, please try again and let us know if this keeps happening"}, error: true});
          });
      };
}

export function getUser(product_slug=null) {
    let url = '/_/api/v1/user/';
    if(product_slug){
        url = `/_/api/v1/product/${product_slug}/board/user/`;
    }
    return function(dispatch) {
        return apiCall.get(url)
          .then(response => {
               dispatch({ type: GET_USER_SUCCESS, payload: response.data });
          })
      };
}

export function emptyUser(){
    const payload = {'anonymous': 'true'};
    return { type: GET_USER_SUCCESS, payload }
}


export function emptyPost(){
    return { type: GET_POST_SUCCESS, payload: null };
}

export function emptySearch() {
    return function(dispatch) {
        dispatch({ type: EMPTY_SEARCH, payload: null })
    }
};

export function updateBoardFormState(new_state){
    return { type: UPDATE_BOARD_FORM_STATE, payload: new_state };
}

export function emptyBoard(){
    return { type: EMPTY_BOARD, payload: {} };
}
