import React, { Component } from 'react'


export class CommentForm extends Component {
    constructor() {
        super()
        this.state = {
            new_comment: '',
            formActive: false,
        }
        this.handleChange = this.handleChange.bind(this)
        this.showForm = this.showForm.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.clearForm = this.clearForm.bind(this)
    }
    componentDidMount(){
        this.mounted = true;
        if(this.props.reply){
            this.commentInput.focus();
        }
    }
    componentWillUnmount(){
      this.mounted = false;
    }
    handleChange(event) {
        this.setState({ new_comment: event.target.value })
    }
    showForm(event) {
        event.preventDefault()
        if (this.props.user && !this.props.user.uuid) {
            this.props.history.push({pathname: "/signup/", state: { modal: true }})
        } else {
            this.setState({ formActive: true })
        }
    }
    clearForm(e) {
        if(e){
            e.preventDefault();
        }
        this.setState({ formActive: false, new_comment: '' })
    }
    handleSubmit(event) {
        event.preventDefault()
        const data = { content: this.state.new_comment }
        let url = `/_/api/v1/product/${this.props.board.product.slug}/board/posts/p/${this.props.post.uuid}/comment/create/`
        if(this.props.comment){
            url = `/_/api/v1/product/${this.props.board.product.slug}/board/posts/p/${this.props.post.uuid}/comment/${this.props.comment.uuid}/reply/`;
        }
        this.props.createComment(url, data).then(function(e){
            if(this.mounted){
                if(this.props.toggleReply){
                    this.props.toggleReply()
                }else{
                    this.clearForm();
                }
            }
        }.bind(this))
    }
    render() {
        const formActive = this.state.formActive || this.props.reply;
        let formActiveClass = ''
        if (formActive) {
            formActiveClass = 'active'
        }
        return (
            <div>
                <form
                    className={`comments-form ${formActiveClass}`}
                    onSubmit={this.handleSubmit}
                >
                    <div>
                        <div className="field" onClick={this.showForm}>
                            <textarea
                                className="textarea"
                                type="text"
                                rows={formActive ? 2 : 1}
                                disabled={!this.props.user.uuid}
                                placeholder={this.props.reply ? "Leave a reply..." : "Leave a comment..."}
                                value={this.state.new_comment}
                                onChange={this.handleChange}
                                ref={(input) => { this.commentInput = input}}
                            />
                        </div>
                        {formActive && (
                            <div className="has-text-right">
                                {!this.props.reply &&
                                    <button
                                        type="submit"
                                        className="button"
                                        onClick={this.clearForm}
                                        style={{
                                            marginRight: '1rem',
                                            color: '#555',
                                        }}
                                    >
                                        Cancel
                                    </button>
                                }
                                {(this.props.user && this.props.user.uuid) &&
                                    <button
                                        type="submit"
                                        className="button is-primary"
                                        style={{
                                            borderColor: this.props.board.color,
                                            background: this.props.board.color,
                                            color: 'white',
                                        }}
                                    >
                                        Share comment
                                    </button>}
                            </div>
                        )}
                    </div>
                </form>
            </div>
        )
    }
}

export default CommentForm;
