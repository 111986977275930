import React, { Component } from 'react'


export class UpArrowIcon extends Component {
    render() {
        return (
            <svg width={this.props.size || "15"} height={this.props.size || "15"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{verticalAlign: "middle"}}>
                <circle cx="12" cy="12" r="10" fill={this.props.bg_color || "#ddd"} />
                <path fill={this.props.color || "#888"} d="M14 12v5a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-5H8a1 1 0 0 1-.7-1.7l4-4a1 1 0 0 1 1.4 0l4 4A1 1 0 0 1 16 12h-2z"/>
            </svg>
        )
    }
}

export default UpArrowIcon
