import React, { Component } from 'react'

const hexColorRegex = require('hex-color-regex')

class ColerPicker extends Component {
    render() {
        const default_colors = this.props.default_colors || ['#9b0c16', '#0099e5', '#511378', '#0abf53', '#d20962',
                                                             '#f48924', '#48a9c5', '#1cc7d0', '#0c3866', '#db3552',
                                                             '#84754e', '#ae63e4', '#85c446']
        const color = this.props.default_color
        let validated_color = color
        if(!hexColorRegex({strict: true}).test(color)){
            validated_color = '#3A9F89'
        }
        if(this.props.keep_color){
            const keep_color = this.props.keep_color
            let validated_keep_color = keep_color
            if(!hexColorRegex({strict: true}).test(keep_color)){
                validated_keep_color = '#3A9F89'
            }
            if(default_colors.indexOf(validated_keep_color) === -1){
                default_colors[0] = validated_keep_color
            }
        }
        return (
            <div className="color-picker" style={{padding: "15px 9px 9px 15px", border: "1px solid #eee", borderRadius: "4px"}}>
                <div className="field has-addons" style={{marginBottom: "0.3rem"}}>
                    <div className="control">
                        <div style={{width: "2rem", height: "100%", background: validated_color, overflow: "hidden", borderRadius: "3px 0 0 3px"}}></div>
                    </div>
                    <div className="control">
                        <input maxLength="7" className="input is-small" type="text" style={{textTransform: "uppercase", color: validated_color }} value={color} onChange={this.props.handleColorChangeInput} />
                    </div>
                </div>
                {default_colors.map(color => (
                    <div key={color} style={{height: "1.5rem", width: "1.5rem", display: "inline-block", background: color, cursor: "pointer", borderRadius: "4px", margin: "0 0.3rem 0.3rem 0"}} onClick={(e) => this.props.handleColorChange(e, color)}></div>
                ))}
            </div>
        )
    }
}

export default ColerPicker
