import React, { Component } from 'react'


export class ExternalWindow extends Component {
    render() {
        return (
            <svg height={this.props.size || "20"} width={this.props.size || "20"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon-external-window" style={{verticalAlign: "middle", marginTop: "-0.2rem"}}>
                <path fill={this.props.color || "#ccc"} d="M12 8a1 1 0 0 1-1 1H5v10h10v-6a1 1 0 0 1 2 0v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9c0-1.1.9-2 2-2h6a1 1 0 0 1 1 1z"/>
                <path fill={this.props.color || "#ccc"} d="M19 6.41L8.7 16.71a1 1 0 1 1-1.4-1.42L17.58 5H14a1 1 0 0 1 0-2h6a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0V6.41z"/>
            </svg>
        )
    }
}

export default ExternalWindow
