import React, { Component } from 'react'


export class UpArrowNoBgIcon extends Component {
    render() {
        return (
            <svg width={this.props.width || "10px"} height={this.props.height || "12px"} viewBox="0 0 20 24">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g fillRule="nonzero" fill={this.props.color || "#888"}>
                        <path d="M14.0112468,11.9858405 L14.0112468,21.9976401 C14.0112468,23.1035129 13.1132986,24 12.0056234,24 L7.99437662,24 C6.88670141,24 5.98875323,23.1035129 5.98875323,21.9976401 L5.98875323,11.9858405 L1.97750646,11.9858405 C1.17510635,11.9745871 0.456665521,11.4869038 0.151121692,10.7460762 C-0.154422136,10.0052486 0.011755432,9.15390345 0.573570091,8.5818286 L8.59606363,0.572388915 C9.37593727,-0.190796305 10.6240627,-0.190796305 11.4039364,0.572388915 L19.4264299,8.5818286 C19.9882446,9.15390345 20.1544221,10.0052486 19.8488783,10.7460762 C19.5433345,11.4869038 18.8248936,11.9745871 18.0224935,11.9858405 L14.0112468,11.9858405 Z"></path>
                    </g>
                </g>
            </svg>
        )
    }
}

export default UpArrowNoBgIcon
