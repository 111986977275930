import React, { Component } from 'react'


class PostEditing extends Component {
    constructor(props) {
        super()
        this.state = {
            selectedType: props.post.type_uuid,
            title: props.post.title,
            description: props.post.description,
            formError: null,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this)
        this.updateSelectedType = this.updateSelectedType.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handleChange(event){
        const newState = {formError: null};
        if(event.target.value){
            newState["title"] = event.target.value.charAt(0).toUpperCase() + event.target.value.substr(1)
        }else{
            newState["title"] = ''
        }
        this.setState(newState)
    }
    handleDescriptionChange(event) {
        this.setState({ description: event.target.value, 'formError': null})
    }
    updateSelectedType(e){
        this.setState({selectedType: e.target.value, 'formError': null});
    }
    handleSubmit(event) {
        event.preventDefault()
        const data = {
            title: this.state.title,
            description: this.state.description,
        }
        if(!this.state.title){
            this.setState({formError: "Please add a title"})
        }else if(!this.state.description){
            this.setState({formError: "Please add a description"})
        }else if(Object.keys(this.props.board.types).length > 1 && !this.state.selectedType){
            this.setState({formError: "Please select a feedback type"})
        }else{
            if(this.state.selectedType){
                data['type'] = this.state.selectedType
            }
            this.props.updatePost(this.props.board.product.slug, this.props.post.uuid, data).then(function(){
                this.props.toggleEditing()
            }.bind(this))
        }
    }
    render() {
        const selectStyle = {};
        if(this.state.selectedType === 0){
            selectStyle['color'] = "#999";
        }
        return (
            <div className="columns">
                <div className="column">
                    <form onSubmit={this.handleSubmit}>
                       <div className="field">
                           <label className="label">Title</label>
                           <input
                               className="input"
                               type="text"
                               value={this.state.title}
                               autoFocus={true}
                               onChange={this.handleChange}
                               required={true}
                           />
                       </div>
                       {(Object.keys(this.props.board.types).length) > 1 &&
                               <div className="field">
                                  <label className="label">What type of feedback is this?</label>
                                   <div className="control">
                                       <div className="select is-fullwidth">
                                         <select style={selectStyle} value={this.state.selectedType} onChange={this.updateSelectedType} required={true}>
                                             <option value={0} disabled={true}>- Select type -</option>
                                             {Object.keys(this.props.board.types).map(
                                                 key => (
                                                     <option value={this.props.board.types[key].uuid} key={this.props.board.types[key].uuid}>{this.props.board.types[key].selector_title}</option>
                                                 )
                                             )}
                                         </select>
                                       </div>
                                     </div>
                               </div>

                          }
                           <div className="field">
                               <label className="label">More details</label>
                               <textarea
                                   className="textarea"
                                   type="text"
                                   rows="2"
                                   placeholder="Tell us more about what you're after and why you're making the request or suggestion"
                                   value={this.state.description}
                                   onChange={this.handleDescriptionChange}
                                   required={true}
                               />
                           </div>
                           <div className="field">
                                   <button
                                       type="submit"
                                       className="button is-default is-pulled-left"
                                       onClick={this.props.toggleEditing}
                                   >
                                       Cancel
                                   </button>
                                   <button
                                       type="submit"
                                       className="button is-primary is-pulled-right"
                                       disabled={!this.state.title}
                                       style={{
                                           borderColor: this.props.board.color,
                                           background: this.props.board.color,
                                           color: 'white',
                                           display: "block",
                                       }}
                                   >
                                       Save <span className="is-hidden-mobile">changes</span>
                                   </button>

                           </div>
                       {this.state.formError && <p className="has-text-danger has-text-centered">{this.state.formError}</p>}
                       {this.props.error && <p className="has-text-danger has-text-centered">{this.props.error}</p>}
                   </form>
               </div>
            </div>

        )
    }
}

export default PostEditing
