import React, { Component } from "react";
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {Switch, Route} from "react-router-dom"
import Board from './board/Board'
import Users from './admin/users/Users'
import Dashboard from './admin/dashboard/Dashboard'
import User from './admin/users/User'
import NotFound from './NotFound'
// import AdminBoard from './admin/AdminBoard'
import BoardSettings from './admin/BoardSettings'
import BoardSetTypes from './admin/BoardSetTypes'
import BoardSetStyle from './admin/BoardSetStyle'
import BoardTypeSettings from './admin/BoardTypeSettings'
import BoardAdvancedSettings from './admin/BoardAdvancedSettings'
import BoardTeamSettings from './admin/BoardTeamSettings'
import Modal from './Modal'

import { connect } from 'react-redux'
import { getBoard, emptyBoard } from './../actions/index'
import { withRouter } from 'react-router'

const mapStateToProps = (state) => {
    return { user: state.rootReducer.user,
             board: state.rootReducer.board}
}

class ModalSwitch extends Component {
  previousLocation = this.props.location;

  componentWillUpdate(nextProps) {
    let { location } = this.props;

    // set previousLocation if props.location is not modal
    if (
      nextProps.history.action !== "POP" &&
      (!location.state || !location.state.modal)
    ) {
      this.previousLocation = this.props.location;
    }
  }

  componentDidMount() {
      if (!this.props.board) {
          this.props.getBoard(this.props.match.params.product_slug)
      }
  }

  componentWillUnmount() {
      this.props.emptyBoard()
  }

  render() {
    let { location } = this.props;

    let isModal = !!(
      location.state &&
      location.state.modal &&
      this.previousLocation !== location
    ); // not initial render
    return (
        <>
        {(this.props.user && this.props.board) &&
            <>
                <Switch location={isModal ? this.previousLocation : location}>
                    <Route exact path="/404/" component={NotFound} />
                    <Route exact path="/login/" render={(routerProps) => (Board)({...routerProps, modal: true})} />
                    <Route exact path="/signup/" render={(routerProps) => (Board)({...routerProps, modal: true})} />
                    <Route
                        exact
                        path="/product/:product_slug/users/"
                        component={(Users)}
                    />
                    <Route
                        exact
                        path="/product/:product_slug/feedback/"
                        component={(Dashboard)}
                    />
                    <Route
                        exact
                        path="/product/:product_slug/p/:post_slug/"
                        render={(routerProps) => (Dashboard)({...routerProps, modal: true})}
                    />
                    <Route
                        exact
                        path="/product/:product_slug/settings/"
                        component={BoardSettings}
                    />
                    <Route
                        exact
                        path="/product/:product_slug/settings/types/"
                        component={BoardTypeSettings}
                    />
                    <Route
                        exact
                        path="/product/:product_slug/settings/advanced/"
                        component={BoardAdvancedSettings}
                    />
                    <Route
                        exact
                        path="/product/:product_slug/settings/team/"
                        component={BoardTeamSettings}
                    />
                    <Route
                        exact
                        path="/product/:product_slug/set-types/"
                        component={BoardSetTypes}
                    />
                    <Route
                        exact
                        path="/product/:product_slug/style/"
                        component={BoardSetStyle}
                    />
                    <Route
                        exact
                        path="/product/:product_slug/users/u/:user_uuid"
                        component={User}
                    />

                    <Route exact path="/" component={Board} />
                    <Route exact path="/p/:post_slug/" render={(routerProps) => (Board)({...routerProps, modal: true})} />
                    <Route path="*" exact component={NotFound} />
                </Switch>
                {isModal ? <Route path="/login/" component={Modal} /> : null}
                {isModal ? <Route path="/signup/" component={Modal} /> : null}
                {isModal ? <Route path="/p/:post_slug/" component={Modal} /> : null}
                {isModal ? <Route path="/product/:product_slug/p/:post_slug/" component={Modal} /> : null}
            </>}
         </>
    );
  }
}

export default withRouter(
    connect(
        mapStateToProps,
        { getBoard, emptyBoard }
    )(ModalSwitch)
);
