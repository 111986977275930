import React, { Component } from 'react'
import { CheckIcon } from './../icons/CheckIcon'
import { RightArrowIcon } from './../icons/RightArrowIcon'

class BoardSetTypesInner extends Component {
    constructor(props) {
        super()
        this.state = {
            selectedTypes: props.board.all_types.filter(type => type.active).map(type => type.uuid)
        }
        this.updateTypeStatus = this.updateTypeStatus.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handleSubmit(e){
        e.preventDefault()
        this.props.updateBoardTypes(this.props.board.product.slug, this.state.selectedTypes).then(function(){
            this.props.postSubmit()
        }.bind(this))
    }
    updateTypeStatus(e, uuid){
        e.preventDefault()
        const selectedTypes = [...this.state.selectedTypes]
        if(selectedTypes.indexOf(uuid) > -1){
            selectedTypes.splice(selectedTypes.indexOf(uuid), 1)
        }else{
            selectedTypes.push(uuid)
        }
        this.setState({selectedTypes: selectedTypes})
    }
    render() {
        return (
            <>
                {this.props.board.all_types &&
                    <form onSubmit={this.handleSubmit}>
                        {Object.keys(this.props.board.all_types).map(key => (
                            <ul className="field list-inline cb-list" key={key} onClick={(e) => this.updateTypeStatus(e, this.props.board.all_types[key].uuid)}>
                                  <li>{(this.state.selectedTypes.indexOf(this.props.board.all_types[key].uuid) > -1) ?
                                      <div className="cb-active"><CheckIcon /></div> :
                                      <div className="cb-inactive"></div>
                                  }
                                  </li>
                                  <li>{this.props.board.all_types[key].title}</li>
                            </ul>
                        ))}
                        {(true) ?
                            <ul className="list-inline has-text-centered list-inline-middle">
                                <li style={{marginRight: 0}}>
                                    <button type="submit" className="button is-primary is-medium" disabled={!this.state.selectedTypes.length}>
                                        {this.props.onboarding ?
                                            <span>Continue <RightArrowIcon /></span>
                                            :
                                            <span>Save</span>
                                        }
                                    </button>
                                </li>
                            </ul>: ""

                        }
                    </form>
                }
            </>
        )
    }
}

export default BoardSetTypesInner
